import { useState, useEffect, useCallback, useRef } from 'react'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { class_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import { Formik, useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { classSchema, familySchema, familyStudentSchema, locationSchema, teacherSchema } from '../schema'
import Modal from '../components/modals'
import moment from 'moment'
import CurrencyInput from 'react-currency-input-field'
import BigSlideover from '../components/big-slideover'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { ArrowsRightLeftIcon, FunnelIcon, InformationCircleIcon, MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { classNames } from '../helpers/classname'
import Input from '../components/input'
import { Switch } from '@headlessui/react'
import ColumnSlideOver from '../components/column_slide_over'
import logo from '../assets/icons/logo.png'
import BigModal from '../components/bigModal'
import { CiFilter } from "react-icons/ci";

const initialModalState = {
  type: '',
  state: false,
  index: null,
  id: '',
  data: {
    className: '',
    serviceId: '',
    studentId: '',
    gradeId: 0,
    classMode: '',
    locationId: '',
    startDate: '',
    endDate: '',
    subjectId: '',
    teacherId: '',
    teacherHourlyRate: '',
    frequency:'',
    duration: '',
    termName:'',
  },
}

const initialTeacherModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    mobileNo: '',
    wwcNo: '',
    vitNumber: '',
    ratePerHour: null,
    qualification: '',
    subjectId: '',
    accountNo: '',
    BSB: '',
    locationId: '',
    abn: '',
    tfn: '',
    membershipNumber: '',
    super: '',
    abnorusi: '',
    usi: '',
    gstValue: '',
    entityName: '',
    abnStatus: ''
  },
}

const classModes = [
  {
    id: 'Offline',
    name: 'Offline',
  },
  {
    id: 'Online',
    name: 'Online',
  },
]

const Terms = [
  {
    id: 'Term 1',
    name: 'Term 1',
  },
  {
    id: 'Term 2',
    name: 'Term 2',
  },
  {
    id: 'Term 3',
    name: 'Term 3',
  },
  {
    id: 'Term 4',
    name: 'Term 4',
  },
]

const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]


const initialMarkAsPaidModalState = {
  data_type: 'add',
  data_state: false,
  data_index: null,
  data_id: '',
  data_Data: {
    startDate: '',
    startTime: '',
    teacherId:'',
    grade:'',
    endTime:'',
    subject:'',
    teacherRate:'',
    location:'',
    index:'',
    duration:'',
  },
}

const initialFamilyModalState = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    additionalEmail: '',
    mobileNo: '',
    workNo: '',
    homeNo: '',
    address1: '',
    address2: '',
    parentState: '',
    city: '',
    country: 'Australia',
    postCode: '',
    center: 0,
    additionalInfo: '',
  },
}

const initialStudentModalState = {
  type: '',
  modalState: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    studentPhone: '',
    address1: '',
    address2: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    studentState: '',
    city: '',
    postCode: '',
    selectFamily: '',
    grade: '',
  },
}

const titles = [
  {
    id: 'Mr',
    name: 'Mr',
  },
  {
    id: 'Mrs',
    name: 'Mrs',
  },
  {
    id: 'Miss',
    name: 'Miss',
  },
]

const initialLocationModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    address: '',
    pointOfContact: '',
    is_active: true,
    accountName: '',
    BSB: '',
    accountNumber: '',
    abn: '',
    gst: '',
    phoneNumber: '',
    emailAddress: '',
    xeroAPIKey: '',
    state: '',
    city: '',
    postCode: '',
    state: '',
    entityName: '',
    entityTypeName: '',
    status: '',
    entityTypeCode: '',
    googleurl: '',
    facebook: '',
    pointOfContact: '',
    brandingThemeID: '',
    sendGridAccount: null
  },
}

const initialInvoiceModal = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data:{}
}

const Active = [
  { id: true, name: 'Active' },
  { id: false, name: 'Inactive' },
]

const initialMergeModal = {
  data_type: 'add',
  data_state: false,
  data_index: null,
  data_id: '',
  data_Data: {
    startDate: '',
    startTime: '',
    teacherId:'',
    grade:'',
    endTime:'',
    subject:'',
    teacherRate:'',
    location:'',
    index:'',
    duration:'',
  },
}

const typeOfServiceOptions = [
  {
    id: 'Regular',
    name: 'Regular',
  },
  {
    id: 'Workshop',
    name: 'Workshop',
  },
]

const ClassMaster = () => {
  const [modal, setModal] = useState(initialModalState)
  const initialValues = {}
  const [formState, setFormState] = useState(initialValues)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState('')
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [locationFilter, setLocationFilter] = useState('')
  const [location, setLocation] = useState([])
  const [TabData, setTabData] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  //pagination End
  const [service, setService] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [teacher, setTeacher] = useState()
  const [scheduleData, setScheduleData] = useState([])
  const [classData, setClassData] = useState([])
  const [submitSchedule, setSubmitSchedule] = useState([])
  const [endDateValue, setEndDateValue] = useState()
  const [studentList, setStudentList] = useState([])
  const [confirmationDialogRemove, setConfirmationDialogRemove] = useState(false)
  const [confirmationDialogInactive, setConfirmationDialogInactive] = useState(false)
  const [studentValue, setStudentValue] = useState()
  const [payload, setPayload] = useState([])
  const [markAsPaidModal, setMarkAsPaidModal] = useState(initialMarkAsPaidModalState)
  const [duration, setDuration] = useState()  
  const [scheduleDatas, setScheduleDatas] = useState([])
  const [frequency ,setFrequency] = useState(10)
  const [studentState, setStudentState] = useState([])
  const [studentCity, setStudentCity] = useState([])
  const [defaultState, setDefaultState] = useState([])
  const [studentEnabled, setStudentEnabled] = useState('Prospective')
  const [parentEnabled, setParentEnabled] = useState('Prospective')
  const [postCodeError, setPostCodeError] = useState('')
  const [studentModal, setStudentModal] = useState(initialStudentModalState)  
  const [grade, setGrade] = useState([])
  const [family, setFamily] = useState([])
  const [familyDetails, setFamilyDetails] = useState({})
  const [familyModal, setFamilyModal] = useState(initialFamilyModalState)
  const [teacherModal, setTeacherModal] = useState(initialTeacherModalState)
  const [enabled, setEnabled] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredField, setHoveredField] = useState(null);
  const [abnStatus, setAbnStatus] = useState()
  const [entityName, setEntityName] = useState()
  const [gstValue, setGstValue] = useState()
  const [abnError, setAbnError] = useState('')

  const [locationModal, setLocationModal] = useState(initialLocationModalState)
  const [LocationManager, setLocationManager] = useState([])
  const [files, setFiles] = useState([])
  const [locationSearch, setLocationSearch] = useState()
  const [studentError, setStudentError] = useState(false)
  const [studentOptions, setStudentOptions] = useState({})

  const [invoiceModal, setInvoiceModal] = useState(initialInvoiceModal)
  const [discountDescription, setDiscountDescription] = useState([])
  const [statusFilter , setStatusFilter] = useState(null)
  const [termFilter, setTermFilter] = useState(null)
  const [teacherError, setTeacherError] = useState(false)
  const formik = useFormik({
    initialValues: invoiceModal.data
  })
  const [confirmationInvoiceButton, setConfirmationInvoiceButton] = useState(false)
  const [mergeModal, setMergeModal] = useState(initialMergeModal)
  const [particularSession, setParticularSession] = useState([])
  const [pendingInvoices, setPendingInvoices] = useState([])
  const [selectedStudents, setSelectedStudents] = useState([])
  const paragraphRef = useRef(null);
  const [submittingInvoice, setSubmittingInvoice] = useState(false)
  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const [columns, setColumns] = useState([])
  const [columnFilter, setColumnsFilter] = useState([])
  const [openSlider, setOpenSlider] = useState(false)
  const [students, setStudent] = useState([])
  let columnName = 'class'
  let user_id = JSON.parse(localStorage.getItem('moldKey')).id
  let title = 'Category Columns'
  let description = 'Customize columns according to your preferences'

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    location: '',
    status: null,
    term: null,
    loading: false,
  })

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
        setIsFilterSidebarOpen(!isFilterSidebarOpen)
    setStartDate(filterData.startDate)
    setEndDate(filterData.endDate)
    setLocationFilter(filterData.location)
    setStatusFilter(filterData.status)
    setTermFilter(filterData.term)
  }

  const handleClearFilters = () => {
    setStartDate()
    setEndDate()
    setLocationFilter([])
    setStatusFilter(null)
    setTermFilter(null)
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  const handleClick = (num) => {
    let payload = num
    app_api
      .patch(`/user-setting/${user_id}/${columnName}`, payload)
      .then((res) => {
        setOpenSlider(false)
        getColumns()
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getColumns = () => {
    app_api
      .get(`/user-setting/${user_id}/${columnName}`)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)

        setColumnsFilter(data)
        let dynamic_columns = data.map((key) => {
          if (key.active == true) {
            return {
              Header: key.header == 'Centre' ? 'Location' : key.header,
              accessor: key.table
                ? key.table + '.' + key.column
                : key.column,
            }
          }
        })
        let columns = [...dynamic_columns]
        columns = columns.filter(function (element) {
          return element !== undefined
        })
        setColumns(columns)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const renderTable = useCallback(() => {
    return (
      <>
       <Table
          progPage={page}
          totalCount={count}
          columns={class_column({onEditOpen,onDeleteOpen,onTeacherOpen,onInactiveOpen,onEditLocationOpen,columns})}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData,columns])


  const onEditOpen = async (id, index) => {
    setSubmitSchedule(TabData[index].session)
    setParticularSession(TabData[index].session)
    const session = TabData[index].session.sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
    setEndDateValue(session[session.length - 1]?.start_time)
    let datas = []
    setStudentValue(TabData[index].students.map((l) => l.student))
    setDuration(TabData[index].duration)
    setScheduleData(TabData[index].schedule)
    getFiles(TabData[index].serviceId)
    for (let i = 0; i < TabData[index].students.length; i++) {
      const frequencyValue = await getSessionFrequency(id,TabData[index].students[i].student.id,TabData[index].students[i].createdAt,TabData[index].session)
      let payload = {
        studentId: TabData[index].students[i].student.id,
        studentName: TabData[index].students[i].student.studentsFirstName + ' ' + TabData[index].students[i].student.studentsLastName,
        parentName: TabData[index].students[i]?.student.familyMaster?.lastName + ' ' + TabData[index].students[i]?.student.familyMaster?.firstName,
        mobileNo: TabData[index].students[i].student.familyMaster.mobileNo,
        email: TabData[index].students[i].student.familyMaster.email,
        grade: TabData[index].students[i].student.gradeMaster.grade,
        familyId: TabData[index].students[i].student.familyId,
        status: TabData[index].students[i].student.active,
        pendingInvoice: frequencyValue,
        amount: TabData[index].service.cost
      }
      datas.push(payload)
    }
    setPayload(datas)
    setPendingInvoices(datas)
    setFrequency(session.length)
    setLocationSearch(TabData[index].locationId)
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
  }

  const getSessionFrequency = async (id, studentValue, date,sessions) => {
    let sessionFrequency
    await app_api.get(`family-invoice-item/invoices/classId/${id}`)
      .then((res) => {
        const data = res.data
        const particularStudentData = data.filter((l) => l.studentId == studentValue)
        if (particularStudentData.length > 0) {
          sessionFrequency = sessions.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)).filter(e => moment(e.start_time).isSameOrAfter(moment(date), 'day')).length - particularStudentData.reduce((total, item) => total + item.quantity, 0)
        } else {
          sessionFrequency = sessions.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)).filter(e => moment(e.start_time).isSameOrAfter(moment(date), 'day')).length
        }
      }).catch((err) => {
        console.log(err)
      })
    return sessionFrequency
  }

  const getServiceData = () => {
    let url = `/crm-service/`
    app_api
      .get(url)
      .then((res) => {
        let data = res.data.filter((l) => l.id != 45)
        setService(data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, edit_id: id, index: index }))
    setConfirmationDialogRemove(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
    setSubmitSchedule([])
    setPayload([])
    setStudentError(false)
    setSelectedStudents([])
  }

  const cleanDateModal=()=>{
    setMarkAsPaidModal(initialMarkAsPaidModalState)
  }

  const getLocation = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        
        setError(null)
        setLocation(res.data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getTeacherData = () => {
    let url = `teacher-master`
    app_api
      .get(url)
      .then((res) => {
        const data = res.data
        setTeacher(data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getClassData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let locationValues =
      locationFilter && locationFilter.length > 0
        ? locationFilter.map((value) => value.id)
        : location.map((value) => value.id)
    let url = `class-master/all?page=${page}&size=${limit}&searchFilter=${searchFilter}&email=${email}`
    if (locationValues.length > 0) {
      url += `&location=${locationValues.join(',')}`
    }
    if (startDate != null && endDate != null) url = url + `&start=${startDate}&end=${endDate}`
    if (statusFilter != null) url = url + `&status=${statusFilter.value}`
    if (termFilter != null) url = url + `&search=${termFilter.value}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setFilterData({
          ...filterData,
          loading: false,
        })
        if (res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        setTabData(res.data)
        if (isNaN(res.count / limit)) { setTotalPages(1) } else { setTotalPages(res.count / limit) }
        setCount(res.count)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }
  useEffect(() => {
    getClassData()
  }, [page, limit, searchFilter, locationFilter, endDate, startDate,statusFilter,termFilter])

  const getSubjectData = () => {
    app_api
      .get('subject-master/all')
      .then((res) => res.data)
      .then((res) => {
        let data = res.data.filter((s)=>s.id != 27 && s.id != 29)
        setError(null)
        setSubjectList(data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }


  const getStudentdata = (id) => {
    app_api.get(`student-master/location/${id}`)
      .then((res) => {
        setStudentList(res.data)
      })
  }

  const onInactive = () => {
    const { edit_id, index } = modal
    app_api
      .patch(`/class-master/active/${edit_id}`, { status: false })
      .then((res) => {
        toast.success("Successfully inactivated")
        getClassData()
        setModal((prev) => ({ ...prev, state: false }))
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        if (err.response.data.statusCode == 406) {
          toast.error('Cannot inactivate class ')
        }
        else {
          toast.error('Something went wrong')
        }
        setError(err.toString())
      })
  }

  const onInactiveOpen = (id) => {
    setModal((prev) => ({ ...prev, edit_id: id }))
    setConfirmationDialogInactive(true)
  }

  useEffect(() => {
    getStateDataByBackend()
    getCities()
    getFamilyData()
    getLocation()
    getServiceData()
    getTeacherData()
    getSubjectData()
    getColumns()
    getLocationManagerData()
  }, [])


  useEffect(() => {
    const newFormState = {}
    let start_date = new Date(classData.startDate)
    let freq = frequency
    let current_date = new Date(start_date)
    let data = []
    let count = 0

    scheduleData.forEach((ele) => {
      const today = new Date();
      let endTime = new Date(today.toDateString() + ' ' + ele.from_time);

      const endTimeFormatted = moment(endTime)
        .add(classData.duration, 'minutes')
        .format('HH:mm');

      const day = ele.weekday_name.toLowerCase();
      const prefix = day.substring(0, 3);
      newFormState[day] = true;
      newFormState[`${prefix}_start`] = ele.from_time;
      newFormState[`${prefix}_end`] = endTimeFormatted;
    });
    setFormState(newFormState)

    const processOccurrences = () => {

      const selectedWeekdays = scheduleData.map((e) => e.weekday_name);
      const filterData = scheduleData.filter((e) => selectedWeekdays.includes(e.weekday_name));

      filterData.forEach((e) => {
        if (moment(current_date).day() === getDayOfWeek(e.weekday_name)) {
          const endTime = new Date(current_date.toDateString() + ' ' + e.from_time);
          data.push({
            date: current_date.toISOString(),
            start_time: moment(new Date(current_date.toDateString() + ' ' + e.from_time)),
            end_time: moment(endTime).add(classData.duration, 'minutes'),
            teacherId:classData.teacherId,
            teacherRate:classData.teacherHourlyRate,
            duration:classData.duration,
            sessionMode:classData.classMode
          });
          count++;
        }
      });

      current_date.setDate(current_date.getDate() + 1)

      if (count < freq) {
        setTimeout(processOccurrences, 0);
      } else {
        setSubmitSchedule(data)
      }
    };
    processOccurrences()
  }, [scheduleDatas])


  const deleteClassData = () => {
    const { edit_id, index } = modal
    app_api
      .delete(`/class-master/${edit_id}`)
      .then((res) => {
        toast.success("Successfully deleted class")
        getClassData()
        setConfirmationDialogRemove(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error('Failed to delete class')
      })
  }

  function getDayOfWeek(dayName) {
    let day_name = dayName.charAt(0).toUpperCase() + dayName.slice(1)
    const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
    return daysOfWeek.indexOf(day_name);
  }

  const removePayload = (selectedId) => {
    const { type, id, index } = modal
    if (type == 'edit') {
      app_api
        .delete(`/class-student/class/${id}/student/${selectedId}`)
        .then((res) => {
          const updatedInvoices = pendingInvoices.filter(i => i.studentId != selectedId)
          setPendingInvoices(updatedInvoices)
          getClassData()
          setModal((prev) => ({
            ...prev,
            type: type,
            id: id,
            index: index,
            state: true,
            data: TabData[index],
          }))
          const updatedData = payload.filter((prev) => prev.studentId !== selectedId)
          setPayload(updatedData);
        }).catch((err) => {
          console.log(err)
        })
    } else {
      const updatedData = payload.filter((prev) => prev.studentId !== selectedId)
      setPayload(updatedData);
    }
  };
  
  const createPayload = async() => {
    let newData = Array.from(payload)

    let studentpayload = {
      studentId: studentValue.id,
      studentName: studentValue.studentsFirstName + ' ' + studentValue.studentsLastName,
      parentName: studentValue?.familyMaster?.lastName + ' ' + studentValue?.familyMaster?.firstName,
      mobileNo: studentValue.familyMaster.mobileNo,
      email: studentValue.familyMaster.email,
      grade: studentValue.gradeMaster.grade,
      familyId: studentValue.familyId,
      status: studentValue.active,
    }
    const exists = newData.some(existingPayload => (
      existingPayload.studentId === studentpayload.studentId &&
      existingPayload.studentName === studentpayload.studentName &&
      existingPayload.parentName === studentpayload.parentName &&
      existingPayload.mobileNo === studentpayload.mobileNo &&
      existingPayload.email === studentpayload.email &&
      existingPayload.grade === studentpayload.grade
    ));
    if (!exists) {
      newData.push(studentpayload)
      const { type, id, index } = modal
      if (type === 'edit') {
        let payload = {
          classId: id,
          studentId: studentValue.id,
        }
        await app_api.post('/class-student', payload)
          .then(async (res) => {
            getClassData()
            let data = Array.from(pendingInvoices)
            const frequencyValue = await getSessionFrequency(id,res.data.studentId,res.data.createdAt,TabData[index].session)
            let payload = {
              studentId: studentValue.id,
              studentName: studentValue.studentsFirstName + ' ' + studentValue.studentsLastName,
              parentName: studentValue?.familyMaster?.lastName + ' ' + studentValue?.familyMaster?.firstName,
              mobileNo: studentValue.familyMaster.mobileNo,
              email: studentValue.familyMaster.email,
              grade: studentValue.gradeMaster.grade,
              pendingInvoice: frequencyValue,
              amount: TabData[index].service.cost,
              status: studentValue.active,
            }
            data.push(payload)
            setPendingInvoices(data)
            setModal((prev) => ({
              ...prev,
              type: type,
              id: id,
              index: index,
              state: true,
              data: TabData[index],
            }))
          })
          .catch((err) => {
            console.log(err)
          })
      }
      setStudentError(false)
    } else {
      setStudentError(true)
    }
    setPayload(newData);
  };
  const removeSchedule = (data) => {
    const { type, id, index } = modal
    if (type == 'edit') {
      app_api.delete(`/class-session/${data.id}`)
        .then((res) => {
          const submit = submitSchedule.filter((l) => l.id != data.id)
          setSubmitSchedule(submit)
          setFrequency(submit.length)
          const updatedInvoices = pendingInvoices.map(invoice => {
            return { ...invoice, pendingInvoice: invoice.pendingInvoice - 1 }
          })
          setPendingInvoices(updatedInvoices)
          getClassData()
          setModal((prev) => ({
            ...prev,
            type: type,
            id: id,
            index: index,
            state: true,
            data: TabData[index],
          }))
        }).catch((err) => {
          console.log(err)
        })
    } else {
      const submit = submitSchedule.filter((l) => l.date != data.date && l.start_time != data.start_time)
      setSubmitSchedule(submit)
      setFrequency(submit.length)
    }
  }

  const mergeSessions = async (data, index, location, subjectId, classId) =>{
    let sessionData
    app_api.get(`/class-session/session/start/${data.start_time}/subject/${subjectId}/location/${location}/sessionMode/${data?.sessionMode}`)
      .then((res) => {
        sessionData = res.data.filter((l) => l.classId != classId && l.status != 'Merged')
        if (sessionData.length > 0) {
          const data_Data = {
            startDate: data.start_time,
            startTime: moment(data.start_time).format('HH:mm'),
            teacherId: data.teacherId,
            subject: subjectId,
            teacherRate: data?.teacherRate,
            sessionMode: data?.sessionMode,
            index: index,
            location: location,
            duration: data?.duration,
            sessionOptions: sessionData,
            sessionId: data.id,
          }
          setMarkAsPaidModal((prev) => ({
            ...prev,
            data_type: 'merge',
            data_id: modal.id,
            data_state: true,
            data_Data: data_Data
          }))
        } else {
          toast.error('No session found to be merged')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

    const getStateDataByBackend = () => {
      app_api
        .post('/state-city/state')
        .then((res) => {
          let data = res.data
          let defaultState = data.filter((l) => l.name == 'Victoria')
          setDefaultState(defaultState)
          setStudentState(data)
          
          setError(null)
        })
        .catch((err) => {
          
          setError(err?.response?.data?.message || 'error getting data')
        })
    }
  
    useEffect(() => {
      let payload = {
        countryCode: defaultState[0]?.countryCode.toString(),
        isoCode: defaultState[0]?.isoCode.toString(),
      }
      app_api
        .post('/state-city/city', payload)
        .then((res) => {
          let data = res.data
          if (payload.isoCode === 'VIC') {
            data.push({
              countryCode: 'AU',
              latitude: '-37.66955000',
              longitude: '144.88675000',
              name: 'Lyndhurst',
              stateCode: 'VIC',
            })
          }
          setStudentCity(data)
          
          setError(null)
        })
        .catch((err) => {
          
          setError(err?.response?.data?.message || 'error getting data')
        })
    }, [defaultState])
  
    const getCities = (countryCode, isoCode) => {
      let payload = {
        countryCode: countryCode?.toString(),
        isoCode: isoCode?.toString(),
      }
      app_api
        .post('/state-city/city', payload)
        .then((res) => {
          let data = res.data
          if (isoCode === 'VIC') {
            data.push({
              countryCode: 'AU',
              latitude: '-37.66955000',
              longitude: '144.88675000',
              name: 'Lyndhurst',
              stateCode: 'VIC',
            })
          }
          setStudentCity(data)
          
          setError(null)
        })
        .catch((err) => {
          
          setError(err?.response?.data?.message || 'error getting data')
        })
    }

    
  const handleStudentCheckedChanges = () => {
    if(studentEnabled == 'Prospective'){
      setStudentEnabled('Active')
    }
    else{
      if(studentEnabled == 'Inactive'){
        setStudentEnabled('Active')
      }
      else{
        if(studentEnabled == 'Active'){
          setStudentEnabled('Inactive')
        }
        else{
          setStudentEnabled('Inactive')
        }
      }
    }
  }

  const handleParentCheckedChanges = () => {
    if (parentEnabled == 'Prospective') {
      setParentEnabled('Active')
    } else {
      if (parentEnabled == 'Inactive') {
        setParentEnabled('Active')
      } else {
        if (parentEnabled == 'Active') {
          setParentEnabled('Inactive')
        } else {
          setParentEnabled('Inactive')
        }
      }
    }
  }

  const checkPostCode = (postCode, state) => {
    if (state != undefined || postCode != undefined) {
      let booleanValue
      if (state == 'Australian Capital Territory') {
        if (postCode.startsWith('02'))
          booleanValue =
            parseInt(postCode) >= parseInt('0200') &&
            parseInt(postCode) <= parseInt('0299')
        else if (parseInt(postCode) >= 2600 && parseInt(postCode) <= 2618)
          booleanValue = true
        else if (parseInt(postCode) >= 2900 && parseInt(postCode) <= 2920)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'New South Wales') {
        if (parseInt(postCode) >= 1000 && parseInt(postCode) <= 2599)
          booleanValue = true
        else if (parseInt(postCode) >= 2619 && parseInt(postCode) <= 2899)
          booleanValue = true
        else if (parseInt(postCode) >= 2921 && parseInt(postCode) <= 2999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Northern Territory') {
        if (postCode.startsWith('08'))
          booleanValue =
            parseInt(postCode) >= parseInt('0800') &&
            parseInt(postCode) <= parseInt('0899')
        if (postCode.startsWith('09'))
          booleanValue =
            parseInt(postCode) >= parseInt('0900') &&
            parseInt(postCode) <= parseInt('0999')
        else booleanValue = false
      }
      if (state == 'Queensland') {
        if (parseInt(postCode) >= 4000 && parseInt(postCode) <= 4999)
          booleanValue = true
        else if (parseInt(postCode) >= 9000 && parseInt(postCode) <= 9999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'South Australia') {
        if (parseInt(postCode) >= 5000 && parseInt(postCode) <= 5999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Tasmania') {
        if (parseInt(postCode) >= 7000 && parseInt(postCode) <= 7999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Victoria') {
        if (parseInt(postCode) >= 3000 && parseInt(postCode) <= 3999)
          booleanValue = true
        else if (parseInt(postCode) >= 8000 && parseInt(postCode) <= 8999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Western Australia') {
        if (parseInt(postCode) >= 6000 && parseInt(postCode) <= 6797)
          booleanValue = true
        else if (parseInt(postCode) >= 6800 && parseInt(postCode) <= 6999)
          booleanValue = true
        else booleanValue = false
      }
      if (postCode == '') {
        setPostCodeError(null)
        booleanValue = true
      }

      if (booleanValue) {
        setPostCodeError(null)
        return true
      } else {
        setPostCodeError('Invalid postcode')
      }
    }
    else{
      return true
    }
  }

  const cleanStudentModalData = () => {
    setStudentModal(initialStudentModalState)
  }

  const cleanFamilyModalData = () => {
    setFamilyModal(initialFamilyModalState)
  }

  const onFamilyEditOpen = (id) => {
    app_api
      .get(`/family-master/${id}`)
      .then((res) => {
        let data = res.data
        if (data.parentState) {
          let countryCodeName = studentState.find(
            (l) => l.name == data.parentState
          )?.countryCode
          let isoCodeName = studentState.find(
            (l) => l.name == data.parentState
          )?.isoCode
          getCities(countryCodeName, isoCodeName)
        }
      setError(false)

        setFamilyModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: null,
          state: true,
          data: data,
        }))
        setFamilyDetails(data)
        setParentEnabled(data.active)
        // getStudentData(id)
      })
  }

  const onStudentEditOpen = (id, index) => {
    app_api.get(`/student-master/${id}`)
    .then((res) => {
      const data=res.data
      
    let payload = {
      firstName: data?.studentsFirstName,
      lastName: data?.studentsLastName,
      email: data?.studentsEmail,
      selectFamily: data?.familyId,
      grade: data?.grade,
      mobileNumber: data?.studentPhone,
      center: data?.locations.map(l => l.locationId)
    }

    setStudentModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      modalState: true,
      data: payload,
    }))
    setStudentEnabled(data.active)
  })
  getGradeData()
  }

  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data
        
        setError(null)
        setGrade(data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getFamilyData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `/family-master/select?&email=${email}`
    app_api
      .get(url)
      .then((res) => {
        
        setError(null)
        setFamily(res.data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onTeacherOpen= (id) => {
    app_api
      .get(`/teacher-master/${id}`)
      .then((res) => {
        let data = res.data
      setError(false)
      setEnabled(data.active)
      getAbnValue(data.abn)
      setTeacherModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: null,
          state: true,
          data: data,
        }))
      })
  }

  const handleMouseEnter = (field) => {
    setIsHovered(true);
    setHoveredField(field);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredField(null);
  };

  const getAbnValue = (abnValue) => {
    let guid = "220fe5cd-f965-4f2a-93ce-61fa1626d8cb";
    const url = `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abnValue}&callback=callback&guid=${guid}`;
    fetch(url)
      .then(response => response.text())
      .then(data => {
        const jsonData = extractJsonData(data);
        if (jsonData.Abn) {
          setGstValue(jsonData.Gst);
          setAbnStatus(jsonData.AbnStatus)
          setEntityName(jsonData.EntityName)
          setAbnError(null);
        }
        else {
          setAbnError('Business not found');
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });

  }

  function extractJsonData(responseText) {
    const startIndex = responseText.indexOf('{');
    const endIndex = responseText.lastIndexOf('}');
    const jsonString = responseText.substring(startIndex, endIndex + 1);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  }

  const getLocationManagerData = () =>
  {
    app_api
      .get('users/manager-locations')
      .then((res) =>
      {
        let data = res.data.filter(l => l.is_active == true);
        setLoading(false)
        setError(null)
        setLocationManager(data)
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onEditLocationOpen = (id, index) =>{
    const data = location.filter((e)=>e.id == id)
    setLocationModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: data[0],
    }))
    if(data[0].state){
      let countryCodeName = studentState.find(l => l.name == data[0].state)?.countryCode
      let isoCodeName = studentState.find(l => l.name == data[0].state)?.isoCode
      getCities(countryCodeName, isoCodeName)
    }
  }

  const setDateEdit = (date,i) => {
    let data = Array.from(submitSchedule)
    data[i].deliveryDate = date
    setSubmitSchedule(data)
  }

  const fileEdits = (opt,i)=> {
    let data = Array.from(submitSchedule)
    data[i].files = opt.map(e=>e.value)
    setSubmitSchedule(data)
  }

  const getFiles = (id) =>{
    app_api
    .get(`service-naplan/${id}`)
    .then((res)=>{
      setFiles(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  
  const customFilter = (option, searchText) => {
    if (
      option.data.studentsFirstName.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.studentsLastName.toLowerCase().includes(searchText.toLowerCase()) 
    ) {
      return true;
    } else {
      return false;
    }
  }

  const handleInputChange = characterEntered => {
    let familysearchfilter = characterEntered
    setCategory(familysearchfilter)
  }

  useEffect(() => {
    if(category != '' || locationSearch != undefined) {
      const delayDebounceFn = setTimeout(() => {
        let url = `/student-master/location/${locationSearch}?&size=${limit}&searchFilter=${category}`
        app_api
          .get(url)
          .then((res) => {
            setStudentList(res.data)
          })
          .catch((err) => {
            setError(err?.response?.data?.message || 'error getting data')
          })
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [category,locationSearch])

  const handleCheckboxChange = async (id, checked) => {
    if (checked) {
      setSelectedStudents(prevSelectedStudents => [...prevSelectedStudents, id]);
    } else {
      setSelectedStudents(prevSelectedStudents => prevSelectedStudents.filter(studentId => studentId !== id));
    }
  }

  const handleAllCheckboxChange = async (checked) => {
    if (checked) {
      const selectedStudentIds = pendingInvoices
        .filter(l => l.pendingInvoice > 0)
        .map(e => e.studentId)
      setSelectedStudents(selectedStudentIds)
    } else {
      setSelectedStudents([]);
    }
  }
  
  const HandleInvoice = async (data) => {
    getDiscountDescription()
    const date = new Date()
    date.setDate(date.getDate() + 7)
    let studentsList = []
    const payload = data.students.filter(l => selectedStudents.includes(l.studentId)).map((i) => {
      studentsList.push(i.student)
      return {
        invoiceDate: new Date(),
        invoiceDueDate: date,
        parentValue: i.student.familyMaster.id,
        studentValue: i.student.id,
        locationId: data.locationId,
        service: {
          studentId: i.student.id,
          serviceName: data.service.name
            ? i.student.studentsFirstName + ' '+i.student.studentsLastName + ' - ' + data.className
            : null,
          quantity: pendingInvoices.find((l) => l.studentId == i.student.id)?.pendingInvoice,
          amount: data.service.cost,
          serviceId: data.service.id,
          classId: data.id,
          serviceDescription: data.service.description,
          maxQuantity:pendingInvoices.find((l) => l.studentId == i.student.id)?.pendingInvoice
        },
        typeOfService: data.typeOf,
        amountPayable: data.service.cost * pendingInvoices.find((l) => l.studentId == i.student.id)?.pendingInvoice,
        discount: 0,
        totalAmount: data.service.cost * pendingInvoices.find((l) => l.studentId == i.student.id)?.pendingInvoice,
        createdBy: JSON.parse(localStorage.getItem('moldKey')).email
      }
    })
    setStudent(studentsList)
    setInvoiceModal((prev) => ({ ...prev, state: true, data: payload }))
  }

  const HandleCopy = async (data) => {
    // setModal((prev) => ({ ...prev, state: false }))
    let payload = {
      classMode: data.classMode,
      className: data.className,
      duration: data.duration,
      frequency: data.frequency,
      friday: false,
      gradeId: data.gradeId,
      locationId: data.locationId,
      monday: false,
      saturday: false,
      serviceId: data.serviceId,
      status: true,
      subjectId: data.subjectId,
      sunday: false,
      teacherHourlyRate: data.teacherHourlyRate,
      teacherId: data.teacherId,
      thursday: false,
      tuesday: false,
      typeOf: data.typeOf,
      vce: false,
      wednesday: false,
      mon_start: '',
      tue_start: '',
      wed_start: '',
      thu_start: '',
      fri_start: '',
      sat_start: '',
      sun_start: '',
    }
    setSubmitSchedule([])
    setModal((prev) => ({ ...prev, type: 'add', state: true, data: payload }))
  }

  const getDiscountDescription = () => {
    app_api
      .get('/discount-description')
      .then((res) => {
        let data = res.data
        setError(null)
        setDiscountDescription(data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const createInvoices = () => {
    const { data } = invoiceModal
    const payloadData = data.filter(e => e.totalAmount > 0 && e.discountError !== true)
    app_api
      .post('/enrolment/create/invoice', payloadData)
      .then((res) => {
        setInvoiceModal((prev) => ({ ...prev, state: false }))
        cleanModalData()
        toast.success('Invoice Created Successfully')
        setSubmittingInvoice(false)
        setConfirmationInvoiceButton(false)
        getClassData()
      })
      .catch((err) => {
        if (err.response.status == 424) {
          toast.error('Duplicate Entry')
        } else {
          toast.error('Something Went Wrong')
        }
        setSubmittingInvoice(false)
      }) 
  }

  const handleMerge = (data, name)=>{
    app_api
    .get(`class-session/${data.mergeSessionId}`)
    .then((res) => {
      const payload = {
        className: name,
        startDate: data.start_time,
        startTime: moment(data.start_time).format('HH:mm'),
        teacherId: data.teacherId,
        teacherRate: data?.teacherRate,
        sessionMode: data?.sessionMode,
        duration: data?.duration,
        mergeClassname:res.data.class.className,
        mergeSessionMode:res.data.sessionMode,
        mergeDate:res.data.start_time,
        mergeStartTime:moment(res.data.start_time).format('HH:mm'),
        mergeTeacherId:res.data.teacherId,
        mergeTeacherRate:res.data?.teacherRate,
        mergeDuration:res.data?.duration
      }
      setMergeModal((prev) => ({ ...prev, data_state: true, data_Data: payload }))
    })
    .catch((err) => {
      setError(err?.response?.data?.message || 'error getting data')
    })
  }

  const renderModal = () => {
    const { type, state, id, data } = modal
    data.vce = data?.service?.gradeMaster.vce
    data.typeOf = data?.service?.typeOf
    if (type === 'edit') {
      weekdays.forEach((weekday) => {
        data[weekday] = false;
        // const day = moment(data?.sessions[0]?.from_time).format("dddd");
        // const time = fromTime ? moment(fromTime).format('HH:mm') : null;
        data?.session?.forEach((s) => {
          const day = moment(s.start_time).format("dddd")
          const time = moment(s.start_time).format('HH:mm');
          if (weekday === day.toLowerCase()) {
            data[weekday] = true;
          }
          data[`${day.toLowerCase().substring(0, 3)}_start`] = `${time}`;
        });
      });
    }
    return (
      <Formik
        initialValues={data}
        validationSchema={classSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          if (values.submitType === 'save') {
            setSubmitting(false)
            setClassData({
              ...values,
              duration: parseInt(values.duration),
              teacherHourlyRate: parseInt(values.teacherHourlyRate),
              gradeId: parseInt(values.gradeId),
              createdBy: JSON.parse(localStorage.getItem('moldKey')).email,
            })
            const body = []
            weekdays.forEach((day) => {
              if (values[day]) {
                const prefix = day.substring(0, 3)
                body.push({
                  weekday_name: day.toUpperCase(),
                  from_time: values[`${prefix}_start`],
                  to_time: values[`${prefix}_end`],
                  teacherId: values.teacherId
                })
              }
            })
            setScheduleData(body)
            setScheduleDatas(body)
          } else {
            setSubmitting(true)
            if (parseInt(frequency) === submitSchedule.length) {
              if (modal.type == 'add') {
                if (payload.length > 0) {
                  let classPayload = {
                    className: values.className,
                    serviceId: values.serviceId,
                    classMode: values.classMode,
                    subjectId: values.subjectId,
                    locationId: values.locationId,
                    teacherId: values.teacherId,
                    teacherHourlyRate: parseInt(values.teacherHourlyRate),
                    startDate: values.startDate,
                    frequency: parseInt(frequency),
                    sessions: submitSchedule,
                    duration: parseInt(values.duration),
                    schedule: scheduleData,
                    studentId: payload.map((i) => i.studentId),
                    createdBy: JSON.parse(localStorage.getItem('moldKey')).email,
                    termName: values.termName
                  }

                  app_api
                    .post('/class-master', classPayload)
                    .then((res) => {
                      cleanModalData()
                      toast.success('Successfully created class')
                      setScheduleData([])
                      getClassData()
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message)
                      // cleanModalData()
                    })
                } else {
                  toast.warning('At least one student must be added to the class.')
                  setSubmitting(false)
                }
              } else {
                delete values.location
                delete values.session
                delete values.subject
                delete values.service
                delete values.teacher
                delete values.students
                delete values.sessions
                let classPayload = {
                  className: values.className,
                  serviceId: values.serviceId,
                  classMode: values.classMode,
                  subjectId: values.subjectId,
                  locationId: values.locationId,
                  teacherId: values.teacherId,
                  teacherHourlyRate: parseInt(values.teacherHourlyRate),
                  startDate: values.startDate,
                  frequency: parseInt(frequency),
                  sessions: submitSchedule,
                  studentId: payload.map((i) => i.studentId),
                  updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
                  termName: values.termName
                }
                app_api
                  .patch(`/class-master/${id}`, classPayload)
                  .then((res) => {
                    cleanModalData()
                    toast.success('Successfully updated class')
                    setScheduleData([])
                    getClassData()
                  })
                  .catch((err) => {
                    toast.error('Failed to create class')
                    cleanModalData()
                  })
              }
            } else {
              toast.warning('The number of classes must match the scheduled classes.')
              setSubmitting(false)
            }
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
            title={type === 'add' ? 'Add Class' : 'Edit Class'}
            handleClick={''}
            data={data}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left" ref={paragraphRef}>
                <span className='font-bold text-xl'>Class Details</span>
                <hr className='text-gray-400' />
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Class Name
                        </label>
                      </div>
                      <input
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="className"
                        value={values.className}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('className', false)}
                        placeholder="Enter Class Name..."
                      />
                      {touched.className && (
                        <p className="text-red-700 error_msg">
                          {errors.className}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Select Service
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:font-semibold"
                        name="serviceId"
                        id="serviceId"
                        isSearchable
                        placeholder="Select service"
                        isDisabled={type == 'edit'}
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#414a4c'
                          })
                        }}
                        value={
                          values.serviceId
                            ? service?.map((l) => ({
                              value: service?.find(
                                (e) => e.id == values.serviceId
                              )?.id,
                              label: service?.find(
                                (e) => e.id == values.serviceId
                              )?.name,
                            }))
                            : null
                        }
                        options={service?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            serviceId: option.value,
                            vce: option.gradeMaster.vce,
                            typeOf: option.typeOf
                          })
                          getFiles(option.value)
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.serviceId && (
                        <p className="text-red-700 error_msg">
                          {errors.serviceId}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Grade
                          </label>
                        </div>
                        <input
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="gradeId"
                          value={
                            values.serviceId
                              ? service.find((l) => l.id == values.serviceId)
                                ?.gradeMaster.grade
                              : null
                          }
                          autoComplete="off"
                          onBlur={handleBlur}
                          disabled={true}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              gradeId: service.find(
                                (l) => l.id == values.serviceId
                              )?.grade,
                            })
                          }}
                          onFocus={() => setFieldTouched('gradeId', false)}
                          placeholder="Enter Class Name..."
                        />
                      </div>
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Class Type
                          </label>
                        </div>
                        <input
                          className="block capitalize	w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="className"
                          value={
                            values.serviceId
                              ? service.find((l) => l.id == values.serviceId)
                                ?.sessionType
                              : null
                          }
                          autoComplete="off"
                          onBlur={handleBlur}
                          disabled={true}
                          onChange={handleChange}
                          onFocus={() => setFieldTouched('className', false)}
                          placeholder="Enter Class Name..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Class Mode
                          </label>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="classMode"
                          id="classMode"
                          isSearchable
                          placeholder="Select class mode"
                          value={
                            values.classMode
                              ? classModes?.map((l) => ({
                                value: classModes?.find(
                                  (e) => e.id == values.classMode
                                )?.id,
                                label: classModes?.find(
                                  (e) => e.id == values.classMode
                                )?.name,
                              }))
                              : null
                          }
                          options={classModes?.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              classMode: option.value,
                            })
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.classMode && (
                          <p className="text-red-700 error_msg">
                            {errors.classMode}
                          </p>
                        )}
                      </div>
                      <div className="col-span-2 -mt-1">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Subject
                          </label>
                          <span className="text-red-500 mx-1 ">*</span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="subjectId"
                          id="subjectId"
                          isSearchable
                          placeholder="Select subject"
                          isDisabled={type == 'edit'}
                          value={
                            values.subjectId
                              ? subjectList?.map((l) => ({
                                value: subjectList?.find(
                                  (e) => e.id == values.subjectId
                                )?.id,
                                label: subjectList?.find(
                                  (e) => e.id == values.subjectId
                                )?.subject,
                              }))
                              : null
                          }
                          options={subjectList
                            .filter((l) => {
                              if (values.vce == l.vce) {
                                return true
                              } else {
                                return false
                              }
                            })
                            .map((l) => ({
                              ...l,
                              label: l.subject,
                              value: l.id,
                            }))}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              subjectId: option.value,
                            })
                          }}
                          styles={{
                            singleValue: (provided) => ({
                              ...provided,
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#414a4c'
                            })
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.subjectId && (
                          <p className="text-red-700 error_msg">
                            {errors.subjectId}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                      <div className="col-span-2 flex space-x-5">
                        <div className="w-1/2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Start Date
                            </label>
                            <span className="text-red-500 mx-1 ">*</span>
                          </div>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                              name="startDate"
                              type="date"
                              dateFormat="dd-MM-yyyy"
                              id="startDate"
                              selected={values.startDate ? new Date(values.startDate) : null}
                              isClearable={false}
                              disabled={type == 'edit'}
                              minDate={new Date()}
                              autoComplete="off"
                              onChange={(date) => {
                                setValues((prev) => ({
                                  ...prev,
                                  startDate: date,
                                }))
                              }}
                              placeholderText="Start Date"
                            />
                            {touched.startDate && (
                              <p className="text-red-700 error_msg">
                                {errors.startDate}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="flex ">
                            <label className="block text-sm font-medium text-gray-700">
                              End Date
                            </label>
                            <span className="text-red-500 mx-1 ">*</span>
                          </div>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                              name="endDate"
                              type="date"
                              dateFormat="dd-MM-yyyy"
                              id="endDate"
                              selected={
                                type == 'edit' ? new Date(endDateValue ? endDateValue : null) : null
                              }
                              autoComplete="off"
                              disabled={true}
                              isClearable={false}
                              onChange={(date) => {
                                setValues((prev) => ({
                                  ...prev,
                                  endDate: date,
                                }))
                              }}
                              styles={{
                                singleValue: (provided) => ({
                                  ...provided,
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  color: '#414a4c'
                                })
                              }}
                              placeholderText="End Date"
                            />
                            {/* {touched.endDate && (
                          <p className="text-red-700 error_msg">
                            {errors.endDate}
                          </p>
                        )} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 flex space-x-5">
                        <div className="w-1/2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Duration (minutes)
                            </label>
                            <span className="text-red-500 mx-1 ">*</span>
                          </div>
                          <input
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="duration"
                            value={values.duration}
                            disabled={type == 'edit'}
                            autoComplete="off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onFocus={() => setFieldTouched('duration', false)}
                            placeholder="Enter Duration in minutes..."
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#414a4c'
                              })
                            }}
                          />
                          {touched.duration && (
                            <p className="text-red-700 error_msg">
                              {errors.duration}
                            </p>
                          )}
                        </div>
                        <div className="w-1/2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Term
                            </label>
                            <span className="text-red-500 mx-1 ">*</span>
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:font-semibold"
                            name="term"
                            id="term"
                            isSearchable
                            placeholder="Select Term"
                            isDisabled={type == 'edit'}
                            value={
                              values.termName
                                ? Terms?.map((l) => ({
                                  value: Terms?.find(
                                    (e) => e.id == values.termName
                                  )?.id,
                                  label: Terms?.find(
                                    (e) => e.id == values.termName
                                  )?.name,
                                }))
                                : null
                            }
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#414a4c'
                              })
                            }}
                            options={Terms.map((l) => ({
                              ...l,
                              label: l.name,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              // getStudentdata(option.value)
                              setValues({
                                ...values,
                                termName: option.value,
                              })
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.termName && (
                            <p className="text-red-700 error_msg">
                              {errors.termName}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Location
                          </label>
                          <span className="text-red-500 mx-1 ">*</span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="locationId"
                          id="locationId"
                          isSearchable
                          placeholder="Select location"
                          isDisabled={type == 'edit'}
                          styles={{
                            singleValue: (provided) => ({
                              ...provided,
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#414a4c'
                            })
                          }}
                          value={
                            values.locationId
                              ? location?.map((l) => ({
                                value: location?.find(
                                  (e) => e.id == values.locationId
                                )?.id,
                                label: location?.find(
                                  (e) => e.id == values.locationId
                                )?.name,
                              }))
                              : null
                          }
                          options={location.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            // getStudentdata(option.value)
                            setLocationSearch(option.value)
                            setValues({
                              ...values,
                              locationId: option.value,
                              teacherId: null
                            })
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.locationId && (
                          <p className="text-red-700 error_msg">
                            {errors.locationId}
                          </p>
                        )}
                      </div>
                      <div className="col-span-2">
                        <div className="flex items-center ">
                          <label className="block text-sm font-medium text-gray-700">
                            Number of classes
                          </label>
                          <span className="text-red-500 mx-1">*</span>
                        </div>
                        <input
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          type='number'
                          name="frequency"
                          value={frequency}
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={(event) => {
                            setFrequency(event.target.value)
                          }}
                          min={1}
                          placeholder="Enter Number of classes..."
                        />
                        {touched.frequency && (
                          <p className="text-red-700 error_msg">
                            {errors.frequency}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-4'>
                  <span className='font-bold text-xl'>Teacher Detail</span>
                  <hr className='text-gray-400'></hr>
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Teacher
                          </label>
                          <span className="text-red-500 mx-1 ">*</span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="teacherId"
                          id="teacherId"
                          isSearchable
                          placeholder="Select teacher"
                          value={
                            values.teacherId
                              ? teacher?.map((l) => ({
                                value: teacher?.find(
                                  (e) => e.id == values.teacherId
                                )?.id,
                                label:
                                  teacher?.find((e) => e.id == values.teacherId)
                                    ?.firstName +
                                  ', ' +
                                  teacher?.find((e) => e.id == values.teacherId)
                                    ?.lastName,
                              }))
                              : null
                          }
                          options={
                            teacher?.filter((teacher) =>
                              teacher.subject.some((subject) => subject.subjectId === values.subjectId &&
                                teacher.locationId.includes(values.locationId))
                            )?.map((l) => ({
                              ...l,
                              label: l.firstName + ', ' + l.lastName,
                              value: l.id,
                            }))}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              teacherId: option.value,
                              teacherHourlyRate: option.ratePerHour
                            })
                            if (submitSchedule.length > 0) {
                              submitSchedule.forEach(schedule => {
                                if (new Date(schedule.date) > new Date()) {
                                  schedule.teacherId = option.value;
                                  schedule.teacherRate = option.ratePerHour;
                                }
                              });
                            }
                          }}
                          noOptionsMessage={() => "Please select subject first."}
                          onBlur={handleBlur}
                        />
                        {touched.teacherId && (
                          <p className="text-red-700 error_msg">
                            {errors.teacherId}
                          </p>
                        )}
                      </div>
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Teacher Hourly Rate
                          </label>
                          <span className="text-red-500 mx-1 ">*</span>
                        </div>
                        <CurrencyInput
                          id="teacherHourlyRate"
                          name="teacherHourlyRate"
                          decimalsLimit={2}
                          value={values.teacherHourlyRate}
                          placeholder="Enter Teacher hourly rate..."
                          className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                          prefix="$"
                          autoComplete="off"
                          onValueChange={(value) => {
                            setValues({
                              ...values,
                              teacherHourlyRate: value,
                            })
                            if (submitSchedule.length > 0) {
                              submitSchedule.forEach(schedule => {
                                if (new Date(schedule.date) > new Date()) {
                                  schedule.teacherRate = value
                                }
                              })
                            }
                          }}
                        />
                        {touched.teacherHourlyRate && (
                          <p className="text-red-700 error_msg">
                            {errors.teacherHourlyRate}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-4'>
                  {/* <span className='text-indigo-400'>Students</span>
                <hr className='text-gray-400'></hr> */}
                </div>
                <div className="mt-4 text-left" >
                  <div className='flex justify-between '>
                    <span className='font-bold text-xl' >Class Sessions</span>
                  </div>
                  <hr className='text-gray-400'></hr>
                  <div className={`flex mt-4 max-h-1/3 gap-4 flex-col lg:flex-row `}>
                    <div className={'bg-gray-50 p-2 lg:p-6 rounded-md shadow-md  lg:w-[25%]'}>
                      <form
                        className="space-y-8 divide-y  divide-gray-200"
                        onSubmit={handleSubmit}
                      >
                        <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Weekdays</h2>
                        <div className="flex flex-row px-3 justify-center">
                          <div className="space-y-1 sm:space-y-5 w-full">
                            <div className="space-y-2 sm:space-y-2 sm:pt-10">
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      sunday: e.target.checked,
                                      sun_start: e.target.checked ? prevValues.sun_start : '',
                                    }));
                                  }}
                                  value={values.sunday}
                                  checked={values.sunday}
                                  disabled={type === 'edit'}
                                  autoComplete="off"
                                  name="sunday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="sun"
                                  className="text-sm font-medium mr-[41px] lg:mr-[20px] text-gray-700 sm:mt-px "
                                >
                                  Sun
                                </label>
                                <div className="flex w-5/6 items-center">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="sun_start"
                                    id="sun_start"
                                    autoComplete="off"
                                    disabled={type === 'edit'}
                                    value={values.sun_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.sun_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.sun_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>

                              {/* end */}
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      monday: e.target.checked,
                                      mon_start: e.target.checked ? prevValues.mon_start : '',
                                    }));
                                  }}
                                  value={values.monday}
                                  checked={values.monday}
                                  disabled={type === 'edit'}
                                  autoComplete="off"
                                  name="monday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="mon"
                                  className="text-sm font-medium mr-[38px] lg:mr-[16px] text-gray-700 sm:mt-px "
                                >
                                  Mon
                                </label>
                                <div className="flex w-5/6 items-center">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="mon_start"
                                    id="mon_start"
                                    autoComplete="off"
                                    value={values.mon_start}
                                    disabled={type === 'edit'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.mon_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.mon_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  name="tuesday"
                                  checked={values.tuesday}
                                  autoComplete="off"
                                  value={values.tuesday}
                                  disabled={type === 'edit'}
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      tuesday: e.target.checked,
                                      tue_start: e.target.checked ? prevValues.tue_start : '',
                                    }));
                                  }}
                                  id="tuesday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="tue"
                                  className="text-sm font-medium mr-[44px] lg:mr-[22px] text-gray-700 sm:mt-px "
                                >
                                  Tue
                                </label>
                                <div className="flex w-5/6 items-center">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="tue_start"
                                    id="tue_start"
                                    autoComplete="off"
                                    disabled={type === 'edit'}
                                    value={values.tue_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.tue_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.tue_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  name="wednesday"
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      wednesday: e.target.checked,
                                      wed_start: e.target.checked ? prevValues.wed_start : '',
                                    }));
                                  }}
                                  disabled={type === 'edit'}
                                  value={values.wednesday}
                                  checked={values.wednesday}
                                  id="wednesday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="wed"
                                  className="text-sm font-medium mr-[38px] lg:mr-[16px] text-gray-700 sm:mt-px "
                                >
                                  Wed
                                </label>
                                <div className="flex w-5/6 items-center">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="wed_start"
                                    id="wed_start"
                                    autoComplete="off"
                                    disabled={type === 'edit'}
                                    value={values.wed_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.wed_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.wed_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  name="thursday"
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      thursday: e.target.checked,
                                      thu_start: e.target.checked ? prevValues.thu_start : '',
                                    }));
                                  }}
                                  disabled={type === 'edit'}
                                  value={values.thursday}
                                  checked={values.thursday}
                                  id="thursday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="thu"
                                  className="text-sm font-medium mr-[42px] lg:mr-[20px] text-gray-700 sm:mt-px "
                                >
                                  Thu
                                </label>
                                <div className="flex w-5/6 items-center just">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="thu_start"
                                    id="thu_start"
                                    autoComplete="off"
                                    disabled={type === 'edit'}
                                    value={values.thu_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.thu_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.thu_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  name="friday"
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      friday: e.target.checked,
                                      fri_start: e.target.checked ? prevValues.fri_start : '',
                                    }));
                                  }}
                                  disabled={type === 'edit'}
                                  checked={values.friday}
                                  value={values.friday}
                                  id="friday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="fri"
                                  className="text-sm font-medium mr-[50px] lg:mr-[28px] text-gray-700 sm:mt-px "
                                >
                                  Fri
                                </label>
                                <div className="flex w-5/6 items-center">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="fri_start"
                                    id="fri_start"
                                    autoComplete="off"
                                    disabled={type === 'edit'}
                                    value={values.fri_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.fri_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.fri_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="flex items-center justify-between">
                                <input
                                  type="checkbox"
                                  name="saturday"
                                  onChange={(e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      saturday: e.target.checked,
                                      sat_start: e.target.checked ? prevValues.sat_start : '',
                                    }));
                                  }}
                                  disabled={type === 'edit'}
                                  value={values.saturday}
                                  checked={values.saturday}
                                  id="saturday"
                                  className="mr-3"
                                />
                                <label
                                  htmlFor="sat"
                                  className="text-sm font-medium mr-[46px] lg:mr-[24px] text-gray-700 sm:mt-px "
                                >
                                  Sat
                                </label>
                                <div className="flex w-5/6 items-center">
                                  <input
                                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                    type="time"
                                    name="sat_start"
                                    id="sat_start"
                                    autoComplete="off"
                                    disabled={type === 'edit'}
                                    value={values.sat_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>

                              <div className="ml-20 grid grid-cols-4">
                                <p>
                                  {errors.sat_start ? (
                                    <p className="text-xs text-red-700">
                                      {errors.sat_start}
                                    </p>
                                  ) : null}
                                </p>
                              </div>
                              {/* end */}
                            </div>
                          </div>
                        </div>

                      </form>
                    </div>
                    <div className={'bg-gray-50 lg:p-6 rounded-md shadow-md  lg:w-[75%]'}>
                      <div className="flex justify-between items-center">
                        <div></div>
                        <div>
                          <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Sessions</h2>
                        </div>

                        <div >
                          <Button className="w-16 cursor-pointer"
                            onClick={() =>
                              setMarkAsPaidModal((prev) => ({
                                ...prev,
                                data_type: 'add',
                                data_id: modal.id,
                                data_state: true,
                                data_Data: {
                                  subject: values.subjectId,
                                  location: values.locationId,
                                  classId:values.id
                                }
                              }))}>Add</Button>
                        </div>
                      </div>
                      <div className="mt-4 rounded-lg overflow-auto max-h-[400px] overflow-y-auto">
                        <table className="min-w-full divide-y divide-gray-300 py-2 align-middle sm:px-6 lg:px-8 table-auto">
                          <thead className="bg-gray-50 sticky top-0 z-10">
                            <tr>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Date
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Day
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                From Time
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                To Time
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Teacher Name
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Teacher Rate
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Duration
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Session Mode
                              </th>
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                Status
                              </th>
                              {values.typeOf == "Workshop" && (
                                <>
                                  <th scope="col" className="px-2 py-3 text-left text-sm font-semibold text-gray-900 w-64">
                                    Files
                                  </th>
                                  <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900 w-16">
                                    Delivery Date
                                  </th>
                                </>
                              )}
                              <th scope="col" className="px-2 py-3 text-left text-nowrap text-sm font-semibold text-gray-900">
                                <span className="px-2">Action</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-gray-50 divide-y divide-gray-500 pb-8">
                            {submitSchedule?.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
                              .map((exception, index) => (
                                <tr key={exception.id} className={'h-10'}>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {moment(exception.start_time).format("DD-MM-yy")}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {moment(exception.start_time).format("dddd")}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {moment(exception.start_time).format('h:mm A')}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {moment(exception.end_time).format('h:mm A')}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {teacher?.find((e) => e.id == exception?.teacherId)
                                      ?.firstName +
                                      ' ' +
                                      teacher?.find((e) => e.id == exception?.teacherId)
                                        ?.lastName}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    $ {exception?.teacherRate}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {exception?.duration} min
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {exception?.sessionMode}
                                  </td>
                                  <td className="px-2 py-3 text-nowrap text-sm">
                                    {exception?.status ? exception?.status : '-'}
                                  </td>
                                  {values.typeOf == "Workshop" && (
                                    <>
                                      <td className="px-2 py-3 text-sm ">
                                        <Select
                                          className="text-left w-64 block appearance-none rounded-md  mt-0.5 placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          name="studentId"
                                          id="studentId"
                                          isSearchable
                                          placeholder="Select files"
                                          isClearable
                                          isMulti
                                          value={exception.files ? exception.files.length > 0 ? exception.files.map((l) => ({
                                            value: l,
                                            label: files?.find((e) => e.naplanExamId == l)
                                              ?.pdf.fileName
                                          })) :
                                            {
                                              value: exception.files,
                                              label: files?.find((e) => e.naplanExamId == exception.files)
                                                ?.pdf.fileName
                                            }
                                          : null}
                                          options={files?.map((e) => ({
                                            label: e.pdf.fileName,
                                            value: e.naplanExamId,
                                          }))}
                                          onChange={(option) => {
                                            fileEdits(option, index)
                                          }}
                                          onBlur={handleBlur}
                                          maxMenuHeight={100}
                                        />
                                      </td>
                                      <td className="px-2 py-3 text-nowrap text-sm">
                                        <div className="customDatePickerWidth ">
                                          <DatePicker
                                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                            name="deliveryDate"
                                            id="deliveryDate"
                                            dateFormat="dd-MM-yyyy"
                                            selected={exception?.deliveryDate ? moment(exception?.deliveryDate).toDate() : null}
                                            onChange={(date) => {
                                              setDateEdit(moment(date).format("YYYY-MM-DD"), index)
                                            }}
                                            placeholderText="Change Date"
                                            autoComplete='off'
                                            minDate={new Date()}
                                          />
                                        </div>
                                      </td>
                                    </>
                                  )}
                                  <td className="px-2 py-3 text-nowrap text-sm ">
                                    <button type='button'
                                      onClick={() => {
                                        const data_Data = {
                                          startDate: exception.start_time,
                                          startTime: moment(exception.start_time).format('HH:mm'),
                                          teacherId: exception.teacherId,
                                          subject: values.subjectId,
                                          teacherRate: exception?.teacherRate,
                                          sessionMode: exception?.sessionMode,
                                          index: index,
                                          location: values.locationId,
                                          duration: exception?.duration,
                                          sessionId: exception?.id
                                        }
                                        setMarkAsPaidModal((prev) => ({
                                          ...prev,
                                          data_type: 'edit',
                                          data_id: modal.id,
                                          data_state: true,
                                          data_Data: data_Data
                                        }))
                                      }}
                                      className="cursor-pointer text-blue-500 px-2 py-1 hover:bg-blue-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                      disabled={moment().isAfter(moment(exception.start_time).add(14, 'days')) || exception?.status == "Merged"}><PencilIcon className="w-5" />
                                    </button>
                                    <button type='button'
                                      onClick={() => removeSchedule(exception)} className="cursor-pointer text-red-500 px-2 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                      disabled={moment().isAfter(moment(exception.start_time).add(14, 'days')) || exception?.status == "Merged"}><TrashIcon className="w-5" />
                                    </button>
                                    {type === 'edit' && (
                                      <>
                                        {exception?.status === "Merged" ? (
                                          <button
                                            type='button'
                                            onClick={() => handleMerge(exception, values.className)}
                                            className="cursor-pointer text-green-500 px-2 py-1 hover:bg-green-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                          >
                                            <InformationCircleIcon className="w-5" />
                                          </button>
                                        ) : (
                                          <button
                                            type='button'
                                            onClick={() => mergeSessions(exception, index, values.locationId, values.subjectId, values.id)}
                                            className="cursor-pointer text-green-500 px-2 py-1 hover:bg-green-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                            disabled={moment().isAfter(moment(exception.start_time).add(14, 'days')) || exception?.status === "Merged"}
                                          >
                                            <ArrowsRightLeftIcon className="w-5" />
                                          </button>
                                        )}
                                      </>
                                    )}

                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {type === 'add' && <div className="w-[45%] flex justify-start my-4">
                    <div className='flex '>
                      <Button
                        type="submit"
                        className='justify-center'
                        onClick={() => {
                          setValues({ ...values, submitType: 'save' })
                        }}
                      >
                        Create sessions
                      </Button>
                    </div>
                  </div>}
                </div>
                {<>
                  <div className="mt-4">
                    <div className='flex justify-between items-center ' >
                      <span className='flex'>
                        <p className='font-bold text-xl'>Students</p>
                        <span className='text-white text-md ml-2 bg-indigo-300 w-6 h-6  rounded-full flex justify-center mt-1'><p>{payload.length}</p></span>
                      </span>
                    </div>
                    <hr className='text-gray-400 my-2'></hr>
                    <div className={`mt-1 w-full border text-base`}>
                      <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                        <div class="grid grid-cols-7 gap-4">
                          <div className="flex justify-center mt-2 font-semibold">
                            Student Name
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Parent Name
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Parent Email
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Parent Mobile
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Grade
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Status
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Add / Remove
                          </div>
                        </div>
                      </div>
                      {payload.map((answer, idx) => (
                        <div className="w-full bg-gray-100 shadow-sm">
                          <div class="grid grid-cols-7 gap-4">
                            <div className="flex justify-center mt-2">
                              <span className="cursor-pointer hover:text-indigo-800" onClick={() => onStudentEditOpen(answer.studentId)}>
                                {answer.studentName}
                              </span>
                            </div>
                            <div className="flex justify-center mt-2">
                              <span className="cursor-pointer hover:text-indigo-800" onClick={() => onFamilyEditOpen(answer.familyId)}>
                                {answer.parentName}
                              </span>
                            </div>
                            <div className="flex justify-center mt-2">
                              <span className="cursor-pointer hover:text-indigo-800" onClick={() => onFamilyEditOpen(answer.familyId)}>
                                {answer.email}
                              </span>
                            </div>
                            <div className="flex justify-center mt-2">
                              {answer.mobileNo}
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              {answer.grade}
                            </div>
                            <div className="flex justify-center mt-2">
                              {answer.status}
                            </div>
                            <div className="flex justify-center items-center">
                              <span onClick={() => removePayload(answer.studentId)} className="cursor-pointer text-red-500 px-2 hover:bg-red-800 hover:rounded-md hover:text-white hover:border-slate-300 hover:border-solid">Remove</span>
                            </div>
                          </div>
                          <hr className='text-gray-400 mt-2'></hr>
                        </div>
                      ))}
                      <div className="w-full bg-gray-100 shadow-sm">
                        <div class="grid grid-cols-7 gap-4">
                          <div className="flex justify-center ml-2 font-semibold">
                            <Select
                              className="text-left block appearance-none rounded-md  mt-0.5 placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              name="studentId"
                              id="studentId"
                              isSearchable
                              placeholder="Select students"
                              filterOption={customFilter}
                              onInputChange={handleInputChange}
                              value={values.studentId ? studentOptions?.map((l) => ({
                                value: studentOptions?.find(
                                  (e) => e.id == values.studentId
                                )?.id,
                                label:
                                  studentOptions?.find((e) => e.id == values.studentId)
                                    ?.studentsFirstName +
                                  ', ' +
                                  studentOptions?.find((e) => e.id == values.studentId)
                                    ?.studentsLastName,
                              })) : null}
                              options={
                                studentList.map((l) => ({
                                  ...l,
                                  label: l.studentsFirstName + ', ' + l.studentsLastName,
                                  value: l.id,
                                }))}
                              onChange={(option) => {
                                setValues({
                                  ...values,
                                  studentId: option.value,
                                  grade: option.gradeMaster.grade,
                                  lastName: option.familyMaster?.lastName,
                                  firstName: option.familyMaster?.firstName,
                                  email: option.familyMaster.email,
                                  mobileNo: option.familyMaster.mobileNo,
                                  familyId: option.familyId,
                                  status: option.active,
                                })
                                setStudentValue( option)
                                let variables = Array.from(studentOptions)
                                let student = {
                                  id: option.value,
                                  studentsFirstName: option.studentsFirstName,
                                  studentsLastName: option.studentsLastName
                                }
                                variables.push(student)
                                setStudentOptions(variables)
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="flex justify-center mt-2">
                            {values.lastName ? values.lastName +
                              ' ' +
                              values.firstName : null}
                          </div>
                          <div className="flex justify-center mt-2">
                            {values.email}
                          </div>
                          <div className="flex justify-center mt-2">
                            {values.mobileNo}
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            {values.grade}
                          </div>
                          <div className="flex justify-center mt-2">
                            {values.status}
                          </div>
                          <div className="flex justify-center items-center">
                            {values.studentId && <span
                              className={"cursor-pointer text-green-500 hover:bg-green-800 px-2 hover:rounded-md hover:text-white hover:border-slate-300 hover:border-solid"}
                              onClick={() => {
                                createPayload()
                                setValues((prevValue) => ({
                                  ...prevValue,
                                  studentId: '',
                                  grade: '',
                                  mobileNo: '',
                                  email: '',
                                  lastName: '',
                                  firstName: '',
                                  familyId: '',
                                  status: '',
                                }))
                              }}> Add
                            </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-6 gap-4">
                      {studentError && (
                        <span className="text-red-500 flex justify-center mt-2">Please select another student</span>
                      )}
                    </div>
                  </div>
                </>
                }
                {type === "edit" && (
                  <div className="mt-4">
                    <div className='flex justify-between items-center ' >
                      <span className='flex'>
                        <p className='font-bold text-xl'>Pending Invoices</p>
                        <span className='text-white text-md ml-2 bg-indigo-300 w-6 h-6  rounded-full flex justify-center mt-1'><p>{pendingInvoices.filter(l => l.pendingInvoice > 0 && l.status != 'Inactive').length}</p></span>
                      </span>
                    </div>
                    <hr className='text-gray-400 my-2'></hr>
                    <div className={`mt-1 w-full border`}>
                      <div className="w-full overflow-auto">
                        <table className="min-w-full divide-y divide-gray-200 text-center">
                          <thead>
                            <tr className='bg-stone-300'>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider disabled:cursor-not-allowed w-10">
                                <input
                                  type="checkbox"
                                  onChange={(e) => handleAllCheckboxChange(e.target.checked)}
                                  checked={pendingInvoices.filter(l => l.pendingInvoice > 0).length == selectedStudents.length}
                                  disabled={pendingInvoices.every(invoice => invoice.pendingInvoice <= 0) || pendingInvoices.filter(l => l.pendingInvoice > 0 && l.status !== 'Inactive').length === 0}
                                />
                              </th>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider text-base whitespace-nowrap">Student Name</th>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider text-base whitespace-nowrap">Parent Name</th>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider text-base whitespace-nowrap">Parent Email</th>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider text-base whitespace-nowrap">Parent Mobile</th>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider text-base whitespace-nowrap">Pending Invoices</th>
                              <th className="px-3 py-2 border-b font-semibold tracking-wider text-base whitespace-nowrap">Total Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pendingInvoices.filter(l => l.pendingInvoice > 0 && l.status != 'Inactive').map((answer, idx) => (
                              <tr key={idx} className="divide-y divide-gray-200 bg-gray-100">
                                <td className="px-3 py-2 whitespace-nowrap">
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        answer.studentId,
                                        e.target.checked
                                      )
                                    }
                                    disabled={answer.disable || answer.pendingInvoice <= 0}
                                    checked={selectedStudents.includes(answer.studentId)}
                                  />
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-base">
                                  <span className="cursor-pointer hover:text-indigo-800" onClick={() => onStudentEditOpen(answer.studentId)}>
                                    {answer.studentName}
                                  </span>
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-base">
                                  <span className="cursor-pointer hover:text-indigo-800" onClick={() => onFamilyEditOpen(answer.familyId)}>
                                    {answer.parentName}
                                  </span>
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-base">
                                  <span className="cursor-pointer hover:text-indigo-800" onClick={() => onFamilyEditOpen(answer.familyId)}>
                                    {answer.email}
                                  </span>
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-base">{answer.mobileNo}</td>
                                <td className="px-3 py-2 whitespace-nowrap text-base">
                                  {answer.pendingInvoice < 0 ? 0 : answer.pendingInvoice}
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-base">{`$ ${answer.pendingInvoice * answer.amount}`}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-4 sm:mt-6 flex justify-end gap-2 items-center mb-4">

                  <>
                    <div className=''>
                      <button
                        type="button"
                        className={'bg-white text-red-500 border-2 border-red-500 px-4 py-1.5 hover:text-red-700 duration-500 inline-flex justify-center rounded-md'}
                        onClick={() => { setModal((prev) => ({ ...prev, state: false })) }}
                      >
                        Cancel
                      </button>
                    </div>

                    {type === "edit" && (
                      <>
                        {((moment(endDateValue).isSameOrBefore(moment(), 'day') && type === "edit") || (payload.length == 0 && type === "edit")) && pendingInvoices.every(invoice => invoice.pendingInvoice <= 0) &&
                          <div>
                            <Button
                              type="button"
                              className={'bg-red-500 hover:bg-red-700 duration-500'}
                              onClick={() => { onInactiveOpen(id) }}
                            >
                              Mark as Inactive
                            </Button>
                          </div>}
                          <div>
                          <Button type="button"
                            className={'bg-green-500 hover:bg-green-800 duration-500 disabled:bg-gray-600'}
                            onClick={() => {HandleInvoice(values)}}
                            disabled={selectedStudents.length>0?false:true}>
                            Create Invoice
                          </Button>
                        </div>
                        <div>
                          <Button type="button"
                            className={'bg-indigo-800 hover:bg-indigo-700 duration-500'}
                            onClick={() => {
                              HandleCopy(values);
                              paragraphRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start"
                              })
                            }
                            }>
                            Copy Class
                          </Button>
                        </div>
                      </>
                    )}
                    <div>
                      <Button type="submit"
                        className={'duration-500'}
                        disabled={isSubmitting}
                        onClick={() => setValues({ ...values, submitType: 'saveClose' })}>
                        Submit
                      </Button>
                    </div>
                  </>

                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderMarkAsPaidModal = () => {
    const { data_type, data_state, data_Data, data_index } = markAsPaidModal;
    const firstTeacherId = data_Data.teacherId;
    return (
      <Formik
        initialValues={data_Data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { resetForm, setSubmitting }) => {
          if (data_type != 'merge') {
            if (data_type == 'add') {
              let startDate = values.startDate instanceof Date ? values.startDate : new Date(values.startDate);
              const start_time = new Date(startDate.toDateString() + ' ' + values.startTime);
              app_api
                .get(`class-session/session/teacher/${values.teacherId}/start/${start_time.toISOString()}`)
                .then((res) => {
                  let data = res.data
                  if (data.length > 0) {
                    setSubmitting(false)
                    toast.error('Teacher not available at this time')
                  } else {
                    const endTime = new Date(values.startDate.toDateString() + ' ' + values.startTime);
                    let payload = {
                      date: values.startDate,
                      classId: modal.type == 'edit' ? values.classId : null,
                      start_time: new Date(values.startDate.toDateString() + ' ' + values.startTime),
                      end_time: moment(endTime).add(values.duration, 'minutes'),
                      teacherId: values.teacherId,
                      teacherRate: parseInt(values.teacherRate),
                      sessionMode: values.sessionMode,
                      duration: parseInt(values.duration),
                      attendance: false
                    }
                    if (modal.type == 'edit') {
                      app_api
                        .post('class-session', payload)
                        .then((res) => {
                          const updatedSubmitSchedule = [...submitSchedule, payload]
                          setSubmitSchedule(updatedSubmitSchedule)
                          setFrequency(updatedSubmitSchedule.length)
                          const updatedInvoices = pendingInvoices.map(invoice => {
                            return { ...invoice, pendingInvoice: invoice.pendingInvoice + 1 }
                          })
                          setPendingInvoices(updatedInvoices)
                          cleanDateModal()
                          resetForm(true)
                          getClassData()
                          setSubmitting(false)
                        }).catch((err) => {
                          console.log(err)
                        })
                    } else {
                      const updatedSubmitSchedule = [...submitSchedule, payload]
                      setSubmitSchedule(updatedSubmitSchedule)
                      setFrequency(updatedSubmitSchedule.length)
                      cleanDateModal()
                      resetForm(true)
                      setSubmitting(false)
                    }
                  }
                })
                .catch((err) => {
                  setError(err?.response?.data?.message || 'error getting data')
                })
            } else {
              //edit sessions
              const { type, id, index } = modal
              let startDate = values.startDate instanceof Date ? values.startDate : new Date(values.startDate);
              const start_time = new Date(startDate.toDateString() + ' ' + values.startTime);
              const particularSessionData = { ...particularSession[data_Data.index] }
              // teacher id is not same
              if (particularSessionData.teacherId != values.teacherId) {
                app_api
                  .get(`class-session/session/teacher/${values.teacherId}/start/${start_time.toISOString()}`)
                  .then((res) => {
                    let data = res.data
                    if (data.length > 0) {
                      setSubmitting(false)
                      toast.error('Teacher not available at this time')
                    } else {
                      const updatedDate = { ...submitSchedule[data_Data.index] };
                      updatedDate.teacherId = values.teacherId;
                      updatedDate.date = values.startDate;
                      updatedDate.start_time = new Date(startDate.toDateString() + ' ' + values.startTime);
                      updatedDate.end_time = moment(updatedDate.start_time).add(values.duration, 'minutes');
                      updatedDate.teacherRate = values.teacherRate
                      updatedDate.duration = values.duration
                      updatedDate.sessionMode = values.sessionMode
                      const updatedSchedule = submitSchedule.map((item, index) =>
                        index === data_Data.index ? updatedDate : item
                      );
                      if (type == 'edit') {
                        app_api
                          .patch(`class-session/${values.sessionId}`, updatedDate)
                          .then((res) => {
                            getClassData()
                          })
                          .catch((err) => { console.log(err) })
                      }
                      setSubmitSchedule(updatedSchedule);
                      cleanDateModal()
                      resetForm(true)
                      setSubmitting(false)
                    }
                  })
                  .catch((err) => {
                    setError(err?.response?.data?.message || 'error getting data')
                  })
              }
              else {
                const updatedDate = { ...submitSchedule[data_Data.index] };
                updatedDate.teacherId = values.teacherId;
                updatedDate.date = values.startDate;
                updatedDate.start_time = new Date(startDate.toDateString() + ' ' + values.startTime);
                updatedDate.end_time = moment(updatedDate.start_time).add(values.duration, 'minutes');
                updatedDate.teacherRate = values.teacherRate
                updatedDate.duration = values.duration
                updatedDate.sessionMode = values.sessionMode
                const updatedSchedule = submitSchedule.map((item, index) =>
                  index === data_Data.index ? updatedDate : item
                );
                if (type == 'edit') {
                  app_api
                    .patch(`class-session/${values.sessionId}`, updatedDate)
                    .then((res) => {
                      getClassData()
                    })
                    .catch((err) => { console.log(err) })
                }
                setSubmitSchedule(updatedSchedule);
                cleanDateModal()
                resetForm(true)
                setSubmitting(false)
              }
            }
          } else {
            //merge part
            setSubmitting(true)
            const { sessionId } = values
            let payload = {
              removeSessionId: values.mergeId,
              teacherId: values.teacherId,
              updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
            }
            const { type, id, index } = modal
            app_api.patch(`/class-session/sessionmerge/${sessionId}`, payload)
              .then((res) => {
                toast.success('Successfully Merged Session')
                cleanDateModal()
                // setModal((prev) => ({ ...prev, state: false }))
                resetForm(true)
                setSubmitting(false)
                getClassData()
                const filteredIndex = submitSchedule.findIndex(item => item.id === res.data.id);
                const updatedDate = { ...submitSchedule[filteredIndex] };
                updatedDate.status = res.data.status;
                updatedDate.mergeSessionId = res.data.mergeSessionId
                const updatedSchedule = submitSchedule.map((item, index) =>
                  index === filteredIndex ? updatedDate : item
                );
                setSubmitSchedule(updatedSchedule)
              }).catch((err) => {
                setSubmitting(false)
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          resetForm,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            open={data_state}
            setOpen={() => {
              resetForm(true)
              setMarkAsPaidModal((prev) => ({ ...prev, data_state: false }))
              cleanDateModal()
              setTeacherError(false)
            }}
            title={data_type != 'merge' ? 'Modify Session' : 'Merge Session'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              {data_type != 'merge' ?
                <div className="text-left mt-2">
                  <div className="flex justify-center mb-2">
                  </div>
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Date
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                          name="startDate"
                          id="startDate"
                          dateFormat="dd-MM-yyyy"
                          selected={values?.startDate ? moment(values?.startDate).toDate() : null}
                          onChange={(date) => {
                            setValues((prev) => ({
                              ...prev,
                              startDate: date,
                            }))
                          }}
                          placeholderText="Change Date"
                          autoComplete='off'
                          minDate={new Date()}
                          filterDate={(date) => {
                            const dateString = moment(date).format("YYYY-MM-DD"); // Convert date to string format
                            return !submitSchedule.some(item => moment(item.start_time).format("YYYY-MM-DD") === dateString);
                        }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Day
                        </label>
                        <span className="text-red-700 ml-1"></span>
                      </div>
                      <input
                        className="text-left mt-1 h-10 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                        name="className"
                        value={values?.startDate ? moment(values?.startDate).format('dddd') : null}
                        autoComplete="off"
                        disabled={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Class Name..."
                      />
                    </div>

                  </div>
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Session Mode
                        </label>
                        <span className="text-red-500 mx-1 ">*</span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="classMode"
                        id="classMode"
                        isSearchable
                        placeholder="Select class mode"
                        value={
                          values.sessionMode
                            ? classModes?.map((l) => ({
                              value: classModes?.find(
                                (e) => e.id == values.sessionMode
                              )?.id,
                              label: classModes?.find(
                                (e) => e.id == values.sessionMode
                              )?.name,
                            }))
                            : null
                        }
                        options={classModes?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            sessionMode: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.sessionMode && (
                        <p className="text-red-700 error_msg">
                          {errors.sessionMode}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Duration (minutes)
                        </label>
                        <span className="text-red-500 mx-1 ">*</span>
                      </div>
                      <input
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="duration"
                        value={values.duration}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Duration in minutes..."
                      />
                      {touched.duration && (
                        <p className="text-red-700 error_msg">
                          {errors.duration}
                        </p>
                      )}
                    </div>
                    <div className="col-span-4">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          From Time
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <input
                        className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                        type="time"
                        name="startTime"
                        id="startTime"
                        autoComplete="off"
                        value={values?.startTime ? values?.startTime : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.startTime && (
                        <p className="text-red-700 error_msg">{errors.startTime}</p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Teacher
                        </label>
                        <span className="text-red-500 ml-1 ">*</span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="teacherId"
                        id="teacherId"
                        isSearchable
                        placeholder="Select teacher"
                        value={
                          values.teacherId
                            ? teacher?.map((l) => ({
                              value: teacher?.find(
                                (e) => e.id == values.teacherId
                              )?.id,
                              label:
                                teacher?.find((e) => e.id == values.teacherId)
                                  ?.firstName +
                                ', ' +
                                teacher?.find((e) => e.id == values.teacherId)
                                  ?.lastName,
                            }))
                            : data_Data.teacherId ? teacher?.map((l) => ({
                              value: teacher?.find(
                                (e) => e.id == data_Data.teacherId
                              )?.id,
                              label:
                                teacher?.find((e) => e.id == data_Data.teacherId)
                                  ?.firstName +
                                ', ' +
                                teacher?.find((e) => e.id == data_Data.teacherId)
                                  ?.lastName,
                            })) : null
                        }
                        options={teacher?.filter((teacher) =>
                          teacher.subject.some((subject) => subject.subjectId === data_Data.subject &&
                            teacher.locationId.includes(data_Data.location))
                        )?.map((l) => ({
                          ...l,
                          label: l.firstName + ', ' + l.lastName,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            teacherId: option.value,
                            teacherHourlyRate: option.ratePerHour,
                            teacherLocation: option.locationId
                          })
                        }}
                        noOptionsMessage={() => "Please select subject first."}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Teacher Hourly Rate
                        </label>
                        <span className="text-red-500 mx-1 ">*</span>
                      </div>
                      <CurrencyInput
                        id="teacherHourlyRate"
                        name="teacherHourlyRate"
                        decimalsLimit={2}
                        value={values.teacherRate}
                        placeholder="Enter Teacher hourly rate..."
                        className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                        prefix="$"
                        autoComplete="off"
                        onValueChange={(value) => {
                          setValues({
                            ...values,
                            teacherRate: value,
                          })
                        }}
                      />
                      {touched.teacherRate && (
                        <p className="text-red-700 error_msg">
                          {errors.teacherRate}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end gap-2">
                    <div className="mt-4 sm:mt-6">
                      <Button
                        type="button"
                        className={'bg-red-500 hover:bg-red-700'}
                        onClick={() => setMarkAsPaidModal((prev) => ({ ...prev, data_state: false }))}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="mt-4 sm:mt-6">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </div >
                </div> :
                <div className="text-left mt-2">
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Date
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                          name="startDate"
                          id="startDate"
                          dateFormat="dd-MM-yyyy"
                          selected={values?.startDate ? moment(values?.startDate).toDate() : null}
                          onChange={(date) => {
                            setValues((prev) => ({
                              ...prev,
                              startDate: date,
                            }))
                          }}
                          placeholderText="Change Date"
                          autoComplete='off'
                          minDate={new Date()}
                          filterDate={(date) => {
                            const dates = submitSchedule.map(l => l.start_time)
                            const dateString = date.toISOString();
                            return !dates.some(disabledDate => disabledDate === dateString);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Day
                        </label>
                        <span className="text-red-700 ml-1"></span>
                      </div>
                      <input
                        className="text-left mt-1 h-10 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                        name="className"
                        value={values?.startDate ? moment(values?.startDate).format('dddd') : null}
                        autoComplete="off"
                        disabled={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Class Name..."
                      />
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          From Time
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <input
                        className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                        type="time"
                        name="startTime"
                        id="startTime"
                        autoComplete="off"
                        value={values?.startTime ? values?.startTime : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.startTime && (
                        <p className="text-red-700 error_msg">{errors.startTime}</p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Select class to merge
                        </label>
                        <span className="text-red-500 mx-1 ">*</span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="mergeclass"
                        id="mergeclass"
                        isSearchable
                        placeholder="Select class to merge"
                        value={
                          values.mergeId
                            ? values.sessionOptions?.map((l) => ({
                              value: values.sessionOptions?.find(
                                (e) => e.id == values.mergeId
                              )?.id,
                              label: values.sessionOptions?.find(
                                (e) => e.id == values.mergeId
                              )?.class.className,
                            }))
                            : null
                        }
                        options={values.sessionOptions?.map((l) => ({
                          ...l,
                          label: l.class.className,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            mergeId: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-2">
                    <div className="mt-4 sm:mt-6">
                      <Button
                        type="button"
                        className={'bg-red-500 hover:bg-red-700'}
                        onClick={() => setMarkAsPaidModal((prev) => ({ ...prev, data_state: false }))}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="mt-4 sm:mt-6">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </div >
                </div>}
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const renderMergeModal = () => {
    const {  data_state, data_Data } = mergeModal

    return (
      <Formik
        initialValues={data_Data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { resetForm, setSubmitting }) => {

        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setValues,
          values,
          touched,
          errors,
        }) => (
          <BigModal
            open={data_state}
            setOpen={() => {
              resetForm(true)
              setMergeModal((prev) => ({ ...prev, data_state: false }))
            }}
            title={'Merged Session'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-2 ">
                <div className='flex gap-4'>
                  <div>
                    <h1 className='text-center font-semibold text-md'>Before Merge</h1>
                    <div className="mt-4">
                      <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                      <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Class Name
                            </label>
  
                          </div>
                          <input
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="className"
                            value={values.className}
                            autoComplete="off"
                            onBlur={handleBlur}
                            disabled={true}
                            onChange={handleChange}
                            placeholder="Enter class name ..."
                          />
                          {touched.className && (
                            <p className="text-red-700 error_msg">
                              {errors.className}
                            </p>
                          )}
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Date
                            </label>
                          
                          </div>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                              name="startDate"
                              id="startDate"
                              dateFormat="dd-MM-yyyy"
                              selected={values?.startDate ? moment(values?.startDate).toDate() : null}
                              onChange={(date) => {
                                setValues((prev) => ({
                                  ...prev,
                                  startDate: date,
                                }))
                              }}
                              placeholderText="Change Date"
                              autoComplete='off'
                              minDate={new Date()}
                              filterDate={(date) => {
                                const dateString = moment(date).format("YYYY-MM-DD"); // Convert date to string format
                                return !submitSchedule.some(item => moment(item.start_time).format("YYYY-MM-DD") === dateString);
                              }}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Day
                            </label>
                          </div>
                          <input
                            className="text-left h-10 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            name="className"
                            value={values?.startDate ? moment(values?.startDate).format('dddd') : null}
                            autoComplete="off"
                            disabled={true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter Class Name..."
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Class Mode
                            </label>
  
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="classMode"
                            id="classMode"
                            isSearchable
                            placeholder="Select class mode"
                            value={
                              values.sessionMode
                                ? classModes?.map((l) => ({
                                  value: classModes?.find(
                                    (e) => e.id == values.sessionMode
                                  )?.id,
                                  label: classModes?.find(
                                    (e) => e.id == values.sessionMode
                                  )?.name,
                                }))
                                : null
                            }
                            options={classModes?.map((l) => ({
                              ...l,
                              label: l.name,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                sessionMode: option.value,
                              })
                            }}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color:'#414a4c'
                              })
                            }}
                            isDisabled
                            onBlur={handleBlur}
                          />
                          {touched.sessionMode && (
                            <p className="text-red-700 error_msg">
                              {errors.sessionMode}
                            </p>
                          )}
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Duration (minutes)
                            </label>
  
                          </div>
                          <input
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="duration"
                            value={values.duration}
                            autoComplete="off"
                            onBlur={handleBlur}
                            disabled={true}
                            onChange={handleChange}
                            placeholder="Enter Duration in minutes..."
                          />
                          {touched.duration && (
                            <p className="text-red-700 error_msg">
                              {errors.duration}
                            </p>
                          )}
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              From Time
                            </label>
                          
                          </div>
                          <input
                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            type="time"
                            name="startTime"
                            id="startTime"
                            autoComplete="off"
                            value={values?.startTime ? values?.startTime : null}
                            onChange={handleChange}
                            disabled={true}
                            onBlur={handleBlur}
                          />
                          {touched.startTime && (
                            <p className="text-red-700 error_msg">{errors.startTime}</p>
                          )}
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Teacher
                            </label>
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="teacherId"
                            id="teacherId"
                            isSearchable
                            placeholder="Select teacher"
                            value={
                              values.teacherId
                                ? teacher?.map((l) => ({
                                  value: teacher?.find(
                                    (e) => e.id == values.teacherId
                                  )?.id,
                                  label:
                                    teacher?.find((e) => e.id == values.teacherId)
                                      ?.firstName +
                                    ', ' +
                                    teacher?.find((e) => e.id == values.teacherId)
                                      ?.lastName,
                                }))
                                : data_Data.teacherId ? teacher?.map((l) => ({
                                  value: teacher?.find(
                                    (e) => e.id == data_Data.teacherId
                                  )?.id,
                                  label:
                                    teacher?.find((e) => e.id == data_Data.teacherId)
                                      ?.firstName +
                                    ', ' +
                                    teacher?.find((e) => e.id == data_Data.teacherId)
                                      ?.lastName,
                                })) : null
                            }
                            options={teacher?.filter((teacher) =>
                              teacher.subject.some((subject) => subject.subjectId === data_Data.subject &&
                                teacher.locationId.includes(data_Data.location))
                            )?.map((l) => ({
                              ...l,
                              label: l.firstName + ', ' + l.lastName,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                teacherId: option.value,
                                teacherHourlyRate: option.ratePerHour,
                                teacherLocation: option.locationId
                              })
                            }}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color:'#414a4c'
                              })
                            }}
                            isDisabled={true}
                            noOptionsMessage={() => "Please select subject first."}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Teacher Hourly Rate
                            </label>
  
                          </div>
                          <CurrencyInput
                            id="teacherHourlyRate"
                            name="teacherHourlyRate"
                            decimalsLimit={2}
                            value={values.teacherRate}
                            placeholder="Enter Teacher hourly rate..."
                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            prefix="$"
                            autoComplete="off"
                            onValueChange={(value) => {
                              setValues({
                                ...values,
                                teacherRate: value,
                              })
                            }}
                            disabled={true}
                          />
                          {touched.teacherRate && (
                            <p className="text-red-700 error_msg">
                              {errors.teacherRate}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <ArrowsRightLeftIcon className="w-8 text-green-500" />
                  </div>
                  <div>
                    <h1 className='text-center font-semibold text-md'>After Merge</h1>
                    <div className="mt-4">
                      <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                      <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Class Name
                            </label>
  
                          </div>
                          <input
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="className"
                            value={values.mergeClassname}
                            autoComplete="off"
                            onBlur={handleBlur}
                            disabled={true}
                            onChange={handleChange}
                            placeholder="Enter class name ..."
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Date
                            </label>
                          </div>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                              name="startDate"
                              id="startDate"
                              dateFormat="dd-MM-yyyy"
                              selected={values?.mergeDate ? moment(values?.mergeDate).toDate() : null}
                              onChange={(date) => {
                                setValues((prev) => ({
                                  ...prev,
                                  mergeDate: date,
                                }))
                              }}
                              placeholderText="Change Date"
                              autoComplete='off'
                              minDate={new Date()}
                              filterDate={(date) => {
                                const dateString = moment(date).format("YYYY-MM-DD"); // Convert date to string format
                                return !submitSchedule.some(item => moment(item.start_time).format("YYYY-MM-DD") === dateString);
                              }}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Day
                            </label>
                          </div>
                          <input
                            className="text-left h-10 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            name="className"
                            value={values?.mergeDate ? moment(values?.mergeDate).format('dddd') : null}
                            autoComplete="off"
                            disabled={true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter Class Name..."
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Session Mode
                            </label>
  
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="classMode"
                            id="classMode"
                            isSearchable
                            placeholder="Select class mode"
                            value={
                              values.mergeSessionMode
                                ? classModes?.map((l) => ({
                                  value: classModes?.find(
                                    (e) => e.id == values.mergeSessionMode
                                  )?.id,
                                  label: classModes?.find(
                                    (e) => e.id == values.mergeSessionMode
                                  )?.name,
                                }))
                                : null
                            }
                            options={classModes?.map((l) => ({
                              ...l,
                              label: l.name,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                sessionMode: option.value,
                              })
                            }}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color:'#414a4c'
                              })
                            }}
                            isDisabled
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Duration (minutes)
                            </label>
  
                          </div>
                          <input
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="duration"
                            value={values.mergeDuration}
                            autoComplete="off"
                            onBlur={handleBlur}
                            disabled={true}
                            onChange={handleChange}
                            placeholder="Enter Duration in minutes..."
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              From Time
                            </label>
                          </div>
                          <input
                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            type="time"
                            name="startTime"
                            id="startTime"
                            autoComplete="off"
                            value={values?.mergeStartTime ? values?.mergeStartTime : null}
                            onChange={handleChange}
                            disabled={true}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Teacher
                            </label>
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="teacherId"
                            id="teacherId"
                            isSearchable
                            placeholder="Select teacher"
                            value={
                              values.mergeTeacherId
                                ? teacher?.map((l) => ({
                                  value: teacher?.find(
                                    (e) => e.id == values.mergeTeacherId
                                  )?.id,
                                  label:
                                    teacher?.find((e) => e.id == values.mergeTeacherId)
                                      ?.firstName +
                                    ', ' +
                                    teacher?.find((e) => e.id == values.mergeTeacherId)
                                      ?.lastName,
                                }))
                                : data_Data.mergeTeacherId ? teacher?.map((l) => ({
                                  value: teacher?.find(
                                    (e) => e.id == data_Data.mergeTeacherId
                                  )?.id,
                                  label:
                                    teacher?.find((e) => e.id == data_Data.mergeTeacherId)
                                      ?.firstName +
                                    ', ' +
                                    teacher?.find((e) => e.id == data_Data.mergeTeacherId)
                                      ?.lastName,
                                })) : null
                            }
                            options={teacher?.filter((teacher) =>
                              teacher.subject.some((subject) => subject.subjectId === data_Data.subject &&
                                teacher.locationId.includes(data_Data.location))
                            )?.map((l) => ({
                              ...l,
                              label: l.firstName + ', ' + l.lastName,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                teacherId: option.value,
                                teacherHourlyRate: option.ratePerHour,
                                teacherLocation: option.locationId
                              })
                            }}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color:'#414a4c'
                              })
                            }}
                            isDisabled={true}
                            noOptionsMessage={() => "Please select subject first."}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Teacher Hourly Rate
                            </label>
                          </div>
                          <CurrencyInput
                            id="teacherHourlyRate"
                            name="teacherHourlyRate"
                            decimalsLimit={2}
                            value={values.mergeTeacherRate}
                            placeholder="Enter Teacher hourly rate..."
                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            prefix="$"
                            autoComplete="off"
                            onValueChange={(value) => {
                              setValues({
                                ...values,
                                mergeTeacherRate: value,
                              })
                            }}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 sm:mt-6 flex justify-end gap-2 items-center mb-4">
                  <div className="mt-4 sm:mt-6">
                    <Button
                      type="button"
                      className={'bg-red-500 hover:bg-red-700'}
                      onClick={() => setMergeModal((prev) => ({ ...prev, data_state: false }))}
                    >
                      Close
                    </Button>
                  </div>

                </div>
              </div>
            </form>
          </BigModal>
        )}
      </Formik>
    )
  }
  
  const renderFamilyModal = () => {
    const { type, state  , edit_id, data } = familyModal
    const {
      title,
      firstName,
      lastName,
      email,
      additionalEmail,
      mobileNo,
      workNo,
      homeNo,
      address1,
      address2,
      city,
      parentState,
      country,
      postCode,
      additionalInfo,
    } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={familySchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('/family-master', { ...values, active: parentEnabled,
              createdBy: JSON.parse(localStorage.getItem('moldKey')).email })
              .then((res) => {
                cleanFamilyModalData()
                
                toast.success('Successfully Added Family')
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                cleanFamilyModalData()
                toast.error(err.response.data.message)
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/family-master/${edit_id}`, {
                ...values,
                active: parentEnabled,
                updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
              })
              .then((res) => {
                cleanFamilyModalData()
                
                setSubmitting(false)
                toast.success('Successfully Updated Family')
                resetForm()
              })
              .catch((err) => {
                cleanFamilyModalData()
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() => {
              setFamilyModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Family'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
              <div className="grid grid-cols-4 gap-4 flex flex-wrap w-full">
                  <div className="col-span-2 flex gap-2">
                <div className="w-1/6">
                    <div className="flex mt-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Title
                      </label>
                      {/* <span className='text-red-700 ml-1'>*</span> */}
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="title"
                      id="title"
                      isSearchable
                      loadingMessage="Getting Title..."
                      placeholder="Title..."
                      value={
                        values?.title
                          ? titles?.map((l) => ({
                              value: titles?.find((e) => e.id == values.title)
                                ?.id,
                              label: titles?.find((e) => e.id == values.title)
                                ?.name,
                            }))
                          : null
                      }
                      options={titles.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          title: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={() => setFieldTouched('title', false)}
                    />
                    {touched.title && (
                      <p className="text-red-700 error_msg">{errors.title}</p>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter First Name..."
                      onFocus={() => setFieldTouched('firstName', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.firstName && (
                      <p className="text-red-700 error_msg">
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Last Name..."
                      onFocus={() => setFieldTouched('lastName', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.lastName && (
                      <p className="text-red-700 error_msg">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>
                  
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="email"
                      label="Email Address"
                      value={values.email}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Email Address..."
                      onFocus={() => setFieldTouched('email', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 error_msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Mobile No.
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      id="mobileNo"
                      name="mobileNo"
                      placeholder="Enter Mobile Number"
                      type="tel"
                      value={values.mobileNo}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          mobileNo: e.target.value,
                        })
                      }}
                      onFocus={() => setFieldTouched('mobileNo', false)}
                    />
                    {touched.mobileNo && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.mobileNo}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 1
                      </label>
                      {/*  <span className="text-red-500 ml-1">*</span> */}
                    </div>
                    <Input
                      name="address1"
                      // label="Address Line 1"
                      value={values.address1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address Line 1..."
                    />
                    {touched.address1 && (
                      <p className="text-red-700 error_msg">
                        {errors.address1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      {/*  <span className="text-red-500 ml-1">*</span> */}
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="city"
                      id="city"
                      isSearchable
                      loadingMessage="Getting city..."
                      placeholder="Select city..."
                      value={
                        values.city
                          ? studentCity?.map((l) => ({
                              value: studentCity?.find(
                                (e) => e.name == values.city
                              )?.isoCode,
                              label: studentCity?.find(
                                (e) => e.name == values.city
                              )?.name,
                            }))
                          : null
                      }
                      options={studentCity?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                          setValues({
                            ...values,
                            city: option.value,
                          })
                      }}
                      onFocus={() => setFieldTouched('city', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.city && (
                      <p className="text-red-700 error_msg">{errors.city}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="parentState"
                      id="parentState"
                      isSearchable
                      loadingMessage="Getting state..."
                      placeholder="Select state..."
                      value={
                        values.parentState
                          ? studentState?.map((l) => ({
                              value: studentState?.find(
                                (e) => e.name == values.parentState
                              )?.isoCode,
                              label: studentState?.find(
                                (e) => e.name == values.parentState
                              )?.name,
                            }))
                          : defaultState
                          ? studentState?.map((l) => ({
                              value: studentState?.find(
                                (e) => e.name == defaultState[0].name
                              )?.isoCode,
                              label: studentState?.find(
                                (e) => e.name == defaultState[0].name
                              )?.name,
                            }))
                          : null
                      }
                      options={studentState?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                          setValues({
                            ...values,
                            parentState: option.value,
                          })
                          getCities(option.countryCode, option.isoCode)
                          checkPostCode(values.postCode, option.value)
                      }}
                      onFocus={() => setFieldTouched('parentState', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.parentState && (
                      <p className="text-red-700 error_msg">
                        {errors.parentState}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Postcode
                      </label>
                      {/*  <span className="text-red-500 mx-1">*</span> */}
                    </div>
                    <input
                      name="postCode"
                      label="Postcode"
                      value={values.postCode}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          postCode: e.target.value,
                        })
                        checkPostCode(e.target.value, values.parentState)
                      }}
                      maxLength={4}
                      placeholder="Enter Postcode..."
                      onFocus={() => setFieldTouched('postCode', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.postCode && (
                      <p className="text-red-700 error_msg">
                        {errors.postCode}
                      </p>
                    )}
                    {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Preferred location
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="center"
                      id="center"
                      isSearchable
                      loadingMessage="Getting location..."
                      placeholder="Select a location..."
                      value={
                        values.center
                          ? location?.map((l) => ({
                              value: location?.find(
                                (e) => e.id == values.center
                              )?.id,
                              label: location?.find(
                                (e) => e.id == values.center
                              )?.name,
                            }))
                          : null
                      }
                      options={location.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          center: option.value,
                        })
                      }}
                      onFocus={() => setFieldTouched('center', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.center && (
                      <p className="text-red-700 error_msg">{errors.center}</p>
                    )}
                  </div>
                  <div className="mt-8">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Activate Parent
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={parentEnabled == 'Active'}
                      onChange={() => {
                        handleParentCheckedChanges()
                      }}
                      className={classNames(
                        parentEnabled == 'Active'
                          ? 'bg-indigo-600'
                          : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          parentEnabled == 'Active'
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                </div>
                {type == 'edit' && (
                  <>
                    <div className="">
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Students</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.studentMaster.length}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-3 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                Student Name
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Grade
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Status
                              </div>
                            </div>
                          </div>
                          {familyDetails.studentMaster.map((answer, idx) => (
                            <div className="w-full h-10 bg-gray-100 shadow-sm">
                              <div class="grid grid-cols-3 gap-4">
                                <div className="flex justify-center mt-2">
                                <span className="cursor-pointer hover:text-indigo-300" 
                                // onClick={()=>onStudentEditOpen(answer.id)}
                                >
                                  {answer.studentsFirstName +
                                    ' ' +
                                    answer.studentsLastName}
                                </span>
                                </div>
                                <div className="flex justify-center mt-2">
                                  {answer.gradeMaster?.grade}
                                </div>
                                <div className="flex justify-center mt-2">
                                  {answer.active}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Invoice</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.crmInvoice.length}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-4 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                Invoice Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Total
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Balance
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Status
                              </div>
                            </div>
                          </div>
                          {familyDetails.crmInvoice.map((answer, idx) => {
                            if (answer.xero_invoice_url != null) {
                              return (
                                <div className="w-full h-10 bg-gray-100 shadow-sm">
                                  <div class="grid grid-cols-4 gap-4">
                                    <div className="flex justify-center mt-2">
                                    <span className="cursor-pointer hover:text-indigo-300" 
                                    // onClick={()=>onEditInvoiceOpen(answer.id)}
                                    >

                                      {answer.xero_invoice_no}
                                      </span>
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      ${answer.amountPayable}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      ${answer.xero_invoice_balance}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      {answer.xero_invoice_status}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Payments</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.crmInvoice.reduce((total, item) => total + item.paymentMaster.length, 0)}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-6 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                ID
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Invoice Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Amount
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Date
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Payment Method
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Description
                              </div>
                            </div>
                          </div>
                          {familyDetails.crmInvoice.map((answer, idx) => {
                            if (answer.xero_invoice_url != null) {
                              return (
                                <>
                                  {answer.paymentMaster.map((payment, idx) => {
                                    let numId = payment.id.toString()
                                    let locationName = payment.location.name
                                    let prefix
                                    while (numId.length < 6) {
                                      numId = '0' + numId
                                    }
                                    if (
                                      locationName ==
                                        'Ringwood (Head Office)' ||
                                      locationName == 'Ringwood' ||
                                      locationName == 'Online' ||
                                      locationName == 'Narre Warren' ||
                                      locationName == 'Officer'||
                                      locationName == 'Beaconsfield'
                                    ) {
                                      prefix = 'RWPAY-'
                                    } else {
                                      prefix = 'CNPAY-'
                                    }
                                    return (
                                      <div class="grid grid-cols-6 gap-4">
                                        <div className="flex justify-center mt-2">
                                          {prefix}
                                          {numId}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {answer.xero_invoice_no}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          ${payment.amount}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {moment(payment.date).format(
                                            'DD-MM-YYYY'
                                          )}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {payment.paymentMethod}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {payment.description}
                                        </div>
                                      </div>
                                    )
                                  })}
                                </>
                              )
                            }
                          })}
                        </div>
                      </div>
                      <hr className="mt-4 w-full"/>
                        <div className="mt-4">
                        <div className='flex'>
                          <p>Credit Notes</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.creditNote.length}</p></d>
                        </div>
                          <div className="mt-1 w-full border">
                            <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                              <div class="grid grid-cols-5 gap-4">
                                <div className="flex justify-center mt-2 font-semibold">
                                  Credit Number
                                </div>
                                <div className="flex justify-center mt-2 font-semibold">
                                  Credit Amount
                                </div>
                                <div className="flex justify-center mt-2 font-semibold">
                                  Credit Balance
                                </div>
                                <div className="flex justify-center mt-2 font-semibold">
                                  Status
                                </div>
                                <div className="flex justify-center mt-2 font-semibold">
                                  Description
                                </div>
                              </div>
                            </div>
                            {familyDetails.creditNote?.map((answer, idx) => {
                              if (answer.creditId != null) {
                                return (
                                  <div className="w-full bg-gray-100 shadow-sm">
                                    <div class="grid grid-cols-5 gap-4">
                                      <div className="flex justify-center mt-2">
                                      <span className="cursor-pointer hover:text-indigo-300" 
                                      // onClick={()=>onEditCreditOpen(answer.id)}
                                      >
                                      {answer.creditId}
                                      </span>
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        ${answer.amount}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        ${answer.balanceAmount}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        {answer.status}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        {answer.description}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      <hr className="mt-4 w-full"/>
                        <div className="mt-4">
                        <div className='flex'>
                          <p>Refunds</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.creditNote.reduce((total, item) => total + item.refund.length, 0)}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-4 gap-4">
                              
                              <div className="flex justify-center mt-2 font-semibold">
                                Credit Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Amount
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Date
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Payment Method
                              </div>
                            </div>
                          </div>
                          {familyDetails.creditNote.map((answer, idx) => {
                            if (answer.creditId != null) {
                              return (
                                <>
                                  {answer.refund.map((id, idx) => {
                                    return (
                                      <div className="w-full bg-white shadow-sm">
                                      <div class="grid grid-cols-4 gap-4">
                                        <div className="flex justify-center mt-2">
                                          {answer.creditId}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          ${id.amount}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {moment(id.date).format('DD-MM-YYYY')}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {id.status}
                                        </div>
                                      </div>
                                      </div>
                                    )
                                  })}
                                </>
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-5 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting || postCodeError}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Family'
                      : 'Update Family'}
                  </Button>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderStudent = () => {
    const { type, modalState, data, edit_id } = studentModal

    return (
      <Formik
        initialValues={data}
        validationSchema={familyStudentSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          app_api
            .patch(`/student-master/${edit_id}`, {
              studentsFirstName: values.firstName,
              studentsLastName: values.lastName,
              studentsEmail: values.email,
              grade: values.grade,
              center: values.center,
              studentPhone: values.mobileNumber,
              active: studentEnabled,
              updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
            })
            .then((res) => {
              cleanStudentModalData()
              getClassData()
              const { type, id, index } = modal
              const filteredIndex = payload.findIndex(item => item.studentId === res.data.id);
              const updatedData = { ...payload[filteredIndex] };
              updatedData.status = res.data.active;
              const updatedPayload = payload.map((item, index) =>
                index === filteredIndex ? updatedData : item
              );
              setPayload(updatedPayload);
              setPendingInvoices(updatedPayload)
              if (res.data.active == 'Active') {
                toast.success('Student Activated Successfully')
              } else {
                toast.success('Student updated successfully')
              }

            })
            .catch((err) => {
              toast.error(err.response.data.message)
              cleanStudentModalData()
            })
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={modalState}
            setOpen={() => {
              setStudentModal((prev) => ({ ...prev, modalState: false }))
            }}
            title={'Student'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left">
                <div>
                  {/* Start Existing Parent */}
                  
                    <>
                      <div className="mt-4">
                        <div className='flex'>
                          <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium">
                          Select Family
                        </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        {values.family}
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="selectFamily"
                          id="selectFamily"
                          isSearchable
                          loadingMessage="Getting Family Details..."
                          placeholder="Select Family..."
                          value={
                            values.selectFamily
                              ? family?.map((l) => ({
                                  value: family?.find(
                                    (e) => e.id == values.selectFamily
                                  )?.id,
                                  label:
                                    family?.find(
                                      (e) => e.id == values.selectFamily
                                    )?.lastName +
                                    ', ' +
                                    family?.find(
                                      (e) => e.id == values.selectFamily
                                    )?.firstName,
                                }))
                              : null
                          }
                          options={family?.map((l) => ({
                            ...l,
                            label: l.lastName + ', ' + l.firstName,
                            value: l.id,
                          }))}
                          isDisabled={type == 'edit'}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              selectFamily: option.value,
                            })
                          }}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}                            
                          onFocus={()=>setFieldTouched('selectFamily',false)}

                        />
                      </div>
                      {touched.selectFamily && (
                        <p className="text-red-700 error_msg">
                          {errors.selectFamily}
                        </p>
                      )}
                      <div className="mt-4"></div>
                      <div className="inline-grid grid-cols-2 gap-4 w-full">
                        <div>
                          <div className='flex'>
                            <label className="mb-1 text-sm text-gray-700 font-medium">Student First Name</label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <Input
                            name="firstName"
                            autoComplete="off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            className="mb-2"
                            placeholder="Enter First Name"
                            onFocus={()=>setFieldTouched('firstName',false)}
                            // onChange={(event) =>
                            //   onModalHandleChange(event.target.name, event.target.value)
                            // }
                          />
                          {touched.firstName && (
                            <p className="text-red-700 error_msg mb-2">
                              {errors.firstName}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className='flex'>
                            <label className="mb-1 text-sm text-gray-700 font-medium">Student Last Name</label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <Input
                            name="lastName"
                            autoComplete="off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            className="mb-2"
                            onFocus={()=>setFieldTouched('lastName',false)}
                            placeholder="Enter Last Name"
                            // onChange={(event) =>
                            //   onModalHandleChange(event.target.name, event.target.value)
                            // }
                          />
                          {touched.lastName && (
                            <p className="text-red-700 error_msg mb-2">
                              {errors.lastName}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-4"></div>
                      <div className="inline-grid grid-cols-2 gap-4 w-full">
                        <div>
                          <div className=""></div>
                      <label
                        htmlFor="contactId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Student's Phone No.
                      </label>
                      <Input
                        name="mobileNumber"
                        // label="Student's Phone No."
                        onFocus={()=>setFieldTouched('mobileNumber',false)}
                        value={values.mobileNumber}
                        placeholder="Enter student phone number"
                         // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            mobileNumber: e.target.value,
                          })
                        }}
                      />
                      {touched.mobileNumber && (
                        <p className="text-red-700 mb-2 error_msg">
                          {errors.mobileNumber}
                        </p>
                      )}
                        </div>
                        <div>
                          <div className="">
                            <label className="text-sm font-medium text-gray-700">
                              Select Grade
                            </label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <Select
                            className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="grade"
                            id="grade"
                            isSearchable
                            loadingMessage="Getting grade..."
                            placeholder="Select a grade..."
                            value={
                              values.grade
                                ? grade?.map((l) => ({
                                    value: grade?.find(
                                      (e) => e.id == values.grade
                                    )?.id,
                                    label: grade?.find(
                                      (e) => e.id == values.grade
                                    )?.grade,
                                  }))
                                : null
                            }
                            options={grade?.map((l) => ({
                              ...l,
                              label: l.grade,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                grade: option.value,
                              })
                            }}
                            onBlur={handleBlur}
                            menuPlacement="auto"
                            onFocus={()=>setFieldTouched('grade',false)}
                            maxMenuHeight={110}
                          />
                          {touched.grade && (
                            <p className="text-red-700 error_msg mb-2 mt-2">
                              {errors.grade}
                            </p>
                          )}
                        </div>
                      </div>
                    <div className="mt-4 inline-grid grid-cols-4 gap-4 w-full">
                      <div className='col-span-2'>
                        <div className='flex'>
                          <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium">
                            Email
                          </label>
                          <span className="text-red-700 ml-1"></span>
                        </div>
                        <Input
                          name="email"
                          autoComplete="off"
                          onChange={handleChange}
                          value={values.email}
                          onFocus={() => setFieldTouched('email', false)}
                          className="mb-2"
                          placeholder="Enter Email"
                        />

                        {touched.email && (
                          <p className="text-red-700 error_msg">
                            {errors.email}
                          </p>
                        )}

                      </div>
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Location
                          </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="center"
                          id="center"
                          isSearchable
                          loadingMessage="Getting location..."
                          placeholder="Select a location..."
                          isMulti={true}
                          value={
                            values.center
                              ? values?.center?.map((l) => ({
                                value: l,
                                label: location.find((e) => e.id == l)
                                  ?.name,
                              })) : null
                          }
                          options={location.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              center: option.map((l) => l.value),
                            })
                          }}
                          isClearable={true}
                          onFocus={() => setFieldTouched('center', false)}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                        />
                        {touched.center && (
                          <p className="text-red-700 error_msg">{errors.center}</p>
                        )}
                      </div>
                    </div>
                    </>
                  {/* End Existing Parent */}
                </div>
                <div className='mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-start"
                >
                  <span className="flex flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Activate Student
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={studentEnabled == 'Active'} 
                    onChange={()=>{handleStudentCheckedChanges()}}
                    className={classNames(
                      studentEnabled == 'Active' ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        studentEnabled == 'Active' ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderTeacherModal = () => {
    const { type, state, edit_id, data } = teacherModal
    data.subjectId = data.subject?.map((e) => e.subjectId)
    return (
      <Formik
        initialValues={data}
        validationSchema={teacherSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('/teacher-master', {
                ...values, ratePerHour: parseInt(values.ratePerHour), active: enabled, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) => {
                toast.success("Successfully added teacher")
                cleanModalData()
                getTeacherData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/teacher-master/${edit_id}`, {
                ...values, ratePerHour: parseInt(values.ratePerHour), active: enabled, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName
              })
              .then((res) => {
                getTeacherData()
                toast.success("Successfully updated teacher")
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.success("Failed to update teacher")
                cleanModalData()
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            title={'Teacher'}
            open={state}
            setOpen={() => setTeacherModal((prev) => ({ ...prev, state: false }))}
            data={''}
            pageType="Teacher"
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className='flex bg-yellow-300'>

              </div>
              <div className="mt-4 text-left">
                <span className='font-bold text-xl'>Personal Details</span>
                <hr className='text-gray-400'></hr>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <span className="text-red-500 mx-1 ">*</span>
                      </div>
                      <Input
                        name="firstName"
                        value={values.firstName}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('firstName', false)}
                        placeholder="Enter First Name..."
                      />
                      {touched.firstName && (
                        <p className="text-red-700 error_msg">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Input
                        name="lastName"
                        value={values.lastName}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('lastName', false)}
                        placeholder="Enter Last Name..."
                      />
                      {touched.lastName && (
                        <p className="text-red-700 error_msg">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-4"></div>
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Input
                        name="email"
                        // label="Parent Email"
                        value={values.email}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('email', false)}
                        placeholder="Enter Parent Email..."
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      />
                      {touched.email && (
                        <p className="text-red-700 error_msg">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Mobile Number
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Input
                        name="mobileNo"
                        type="tel"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            mobileNo: e.target.value,
                          })
                        }}
                        onFocus={() => setFieldTouched('mobileNo', false)}
                        value={values.mobileNo}
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                        placeholder="Enter mobile number..."
                      />
                      {touched.mobileNo && (
                        <p className="text-red-700 error_msg">
                          {errors.mobileNo}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='mt-10'>
                  <span className='font-bold text-xl'>Professional Details</span>
                  <hr className='text-gray-400'></hr>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700 ">
                        WWC #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('wwcNo')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'wwcNo' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm">
                            Location Manager should check the WWC #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="wwcNo"
                      value={values.wwcNo}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('wwcNo', false)}
                      placeholder="Enter WWC number..."
                      prefix='WWC'
                    />
                    {touched.wwcNo && (
                      <p className="text-red-700 error_msg">
                        {errors.wwcNo}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        VIT #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('vitNumber')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'vitNumber' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the VIT #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="vitNumber"
                      value={values.vitNumber}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('vitNumber', false)}
                      placeholder="Enter VIT number..."
                    />
                    {touched.vitNumber && (
                      <p className="text-red-700 error_msg">
                        {errors.vitNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Rate Per Hour
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <CurrencyInput
                      id="ratePerHour"
                      name="ratePerHour"
                      decimalsLimit={2}
                      value={values.ratePerHour}
                      placeholder="Enter rate per hour"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      prefix="$"
                      autoComplete='off'
                      onFocus={() => setFieldTouched('ratePerHour', false)}
                      onValueChange={(value) => {
                        setValues({
                          ...values,
                          ratePerHour: value,
                        })
                      }} />
                    {touched.ratePerHour && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.ratePerHour}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Qualification
                      </label>
                      <span className="sr-only">*</span>
                    </div>
                    <Input
                      name="qualification"
                      id="qualification"
                      placeholder="Enter qualification..."
                      value={values.qualification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      autoComplete='off'
                      onFocus={() => setFieldTouched('qualification', false)}
                      maxMenuHeight={110}
                    />
                    {touched.qualification && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.qualification}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="subjectId"
                      id="subjectId"
                      loadingMessage="Getting subjects..."
                      isSearchable
                      placeholder="Select subject..."
                      isMulti={true}
                      options={subjectList?.map((l) => ({
                        ...l,
                        label: l.subject,
                        value: l.id,
                      }))}
                      value={
                        values.subjectId ?
                          values.subjectId?.map((l) => ({
                            value: l,
                            label: subjectList?.find(
                              (e) => e.id == l
                            )?.subject
                          })) : null
                      }
                      onChange={(option) => {
                        setValues({
                          ...values,
                          subjectId: option.map(l => l.value),
                        })
                      }}
                      onFocus={() => setFieldTouched('subject', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.subjectId && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.subjectId}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Location
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="locationId"
                      id="locationId"
                      isSearchable
                      loadingMessage="Getting Location..."
                      placeholder="Select a Location"
                      isMulti={true}
                      value={
                        values.locationId
                          ? values?.locationId?.map((l) => ({
                            value: l,
                            label: location.find((e) => e.id == l)
                              ?.name,
                          }))
                          : null
                      }
                      options={location?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          locationId: option.map((l) => l.value),
                        })
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched('locationId', false)}
                    />
                    {touched.locationId && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.locationId}
                      </p>
                    )}
                  </div>
                </div>
                <div className='mt-10'>
                  <span className='font-bold text-xl'>Banking Details</span>
                  <hr className='text-gray-400'></hr>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">

                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        BSB Number
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      id="BSB"
                      placeholder="Enter BSB Number..."
                      value={values.BSB}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('BSB', false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">
                        {errors.BSB}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Account Number
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNo"
                      id="accountNo"
                      placeholder="Enter Account Number"
                      value={values.accountNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('accountNo', false)}
                    />
                    {touched.accountNo && (
                      <p className="text-red-700 error_msg">
                        {errors.accountNo}
                      </p>
                    )}
                  </div>
                </div>
                <div className='mt-10'>
                  <span className='font-bold text-xl'>Taxation Details</span>
                  <hr className='text-gray-400'></hr>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="mt-4 col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >ABN #</label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('abn')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'abn' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm">
                            Location Manager should check the ABN #
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='flex'>
                      <input
                        className="block w-full appearance-none rounded-md border mt-1 border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="abn"
                        type="text"
                        value={values.abn}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('abn', false)}
                        placeholder="Enter Australian Business Number..."
                      />
                      <p className="w-8 rounded-r-md border-y border-r border-gray-300 mt-1  appearance-none cursor-pointer" onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md mt-1' /></p>
                    </div>
                    {touched.abn && (
                      <p className="text-red-700 error_msg">{errors.abn}</p>
                    )}

                    {abnError && (
                      <p className="text-red-700 error_msg">{abnError}</p>
                    )}
                  </div>

                  <div className="mt-4 col-span-2">
                    <div className='flex '>
                      <label className='block text-sm font-medium text-gray-700' >GST Registeration Date</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="gst"
                      label="GST"
                      type="text"
                      value={values.gstValue}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                      onFocus={() => setFieldTouched('gst', false)}
                    />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="abnStatus"
                      type="text"
                      value={values.abnStatus}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={abnStatus}

                    />
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="entityName"
                      type="text"
                      value={values.entityName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={entityName}
                    />
                  </div>
                </div>

                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        TFN #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('tfn')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'tfn' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the TFN #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="tfn"
                      value={values.tfn}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('tfn', false)}
                      placeholder="Enter TFN number..."
                    />
                    {touched.tfn && (
                      <p className="text-red-700 error_msg">
                        {errors.tfn}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Super #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('super')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'super' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the Super #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="super"
                      value={values.super}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('super', false)}
                      placeholder="Enter super number...."
                    />
                    {touched.super && (
                      <p className="text-red-700 error_msg">
                        {errors.super}
                      </p>
                    )}
                  </div>
                </div>

                <div className='mt-4'>
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Membership #
                        </label>
                        <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('membershipNumber')} onMouseLeave={handleMouseLeave} />
                        {isHovered && hoveredField === 'membershipNumber' && (
                          <div className="bg-gray-100">
                            <p className="mx-2 block text-sm  ">
                              Location Manager should check the Membership #
                            </p>
                          </div>
                        )}
                      </div>
                      <Input
                        name="membershipNumber"
                        value={values.membershipNumber}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('membershipNumber', false)}
                        placeholder="Enter Membership number..."
                      />
                      {touched.membershipNumber && (
                        <p className="text-red-700 error_msg">
                          {errors.membershipNumber}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          ABN #
                        </label>
                        <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('abnorusi')} onMouseLeave={handleMouseLeave} />
                        {isHovered && hoveredField === 'abnorusi' && (
                          <div className="bg-gray-100">
                            <p className="mx-2 block text-sm  ">
                              Location Manager should check the ABN #
                            </p>
                          </div>
                        )}
                      </div>
                      <Input
                        name="abnorusi"
                        value={values.abnorusi}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('abnorusi', false)}
                        placeholder="Enter ABN number..."
                      />
                      {touched.abnorusi && (
                        <p className="text-red-700 error_msg">
                          {errors.abnorusi}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        USI #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('usi')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'usi' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the USI #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="usi"
                      value={values.usi}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('usi', false)}
                      placeholder="Enter USI number..."
                    />
                    {touched.usi && (
                      <p className="text-red-700 error_msg">
                        {errors.usi}
                      </p>
                    )}
                  </div>

                </div>


                <div className='mt-4'>
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Active
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                {type=='edit'&&
                <div className="mt-8 mb-4">
                <div className='flex mb-4'>
                  <p className='font-bold text-xl'>Attendance</p>
                  <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center mt-1'><p>{data?.teacherAtt?.filter(index=> index.attendance == 1).length}</p></d>
                </div>
                <div className="mt-1 w-full border">
                  <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                    <div class="grid grid-cols-5 gap-4">
                      <div className="flex justify-center mt-2 font-semibold">
                        Class Name
                      </div>
                      <div className="flex justify-center mt-2 font-semibold">
                        Subject
                      </div>
                      <div className="flex justify-center mt-2 font-semibold">
                        Date of attended class
                      </div>
                      <div className="flex justify-center mt-2 font-semibold">
                        Start Time
                      </div>
                      <div className="flex justify-center mt-2 font-semibold">
                        End Time
                      </div>
                    </div>
                  </div>
                  {data?.teacherAtt?.filter(index=> index.attendance == 1).map((c, idx) => (
                    <div className="w-full h-10 bg-gray-100 shadow-sm">
                      <div class="grid grid-cols-5 gap-4">
                        <span className="cursor-pointer hover:text-indigo-800" >
                          <div className="flex justify-center mt-2">
                            {c?.class?.className}
                          </div>
                        </span>
                        <div className="flex justify-center mt-2">
                          {c?.class?.subject.subject}
                        </div>
                        <div className="flex justify-center mt-2">
                         {moment(c.date).format('DD-MM-YYYY')}
                        </div>
                        <div className="flex justify-center mt-2">
                          {moment(c?.from_time).format('h:mm A')}
                        </div>
                        <div className="flex justify-center mt-2">
                        {moment(c?.end_time).format('h:mm A')}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              }
                <div className="mt-4 sm:mt-6 flex justify-end gap-2 items-center mb-4">
                <div>
                  <Button
                    type="button"
                    className={'bg-red-500 hover:bg-red-700'}
                    onClick={()=>{{setModal((prev) => ({ ...prev, state: false }))}}}
                  >
                    Cancel
                  </Button>
                </div>
                  <div>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? 'Submitting'
                      : 'Submit'}
                  </Button>
                  </div>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderLocationModal = () =>{
    const { type, state, edit_id, data } = locationModal
    return (
      <Formik
        initialValues={data}
        validationSchema={locationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>{
          if (type === 'add')
          {
            app_api
              .post('/location', {
                ...values, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) =>
              {
                getLocation()
                cleanModalData()
                setSubmitting(false)
                toast.success('Successfully added location')
                resetForm()
              })
              .catch((err) =>
              {
                toast.error(err.response.data.message)
                getLocation()
                // cleanModalData()
                setSubmitting(false)
              })
          } else
          {
            app_api
              .patch(`/location/${edit_id}`, { ...values, gst: gstValue })
              .then((res) =>
              {
                let updatedLocationData = [...location]
                updatedLocationData[modal.index] = modal.data
                setLocation(updatedLocationData)
                getLocation()
                toast.success('Successfully updated location')
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.error(err.response.data.message)
               })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          resetForm,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() =>{
              setLocationModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Location'}
            handleClick={''}
            data={''}
            >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="name"
                  label="Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Enter Name...'
                  onFocus={()=>setFieldTouched('name',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.name && (
                  <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Point of Contact</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="pointOfContact"
                      id="pointOfContact"
                      isSearchable
                      loadingMessage="Getting User..."
                      placeholder="Select a user..."
                    value={
                      values.pointOfContact
                        ? LocationManager?.map((l) => ({
                          value: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.id,
                          label: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.name,
                        }))
                        : null
                    }
                      options={LocationManager.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>{
                      setValues({
                        ...values,
                        pointOfContact: option.value,
                        userId: option.id
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    onFocus={()=>setFieldTouched('city',false)}
                    maxMenuHeight={110}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Phone / Mobile Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      name="phoneNumber"
                      label="Mobile / Phone Number"
                      placeholder='Enter Mobile / Phone Number'
                      type="tel"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.phoneNumber}
                      autoComplete="off"
                      onFocus={()=>setFieldTouched('phoneNumber',false)}
                      onBlur={(e) =>
                      {
                        setValues({
                          ...values,
                          phoneNumber: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.phoneNumber && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email Address</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="emailAddress"
                  label="Email Address"
                  type="text"
                  value={values.emailAddress}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('emailAddress',false)}
                  placeholder="Enter Email Address..."
                />
                {touched.emailAddress && (
                  <p className="text-red-700 error_msg">{errors.emailAddress}</p>
                )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Address Line 1</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="address"
                  label="Address"
                  type="text"
                  value={values.address}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('address',false)}
                  placeholder="Enter Address..."
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                  <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >City</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                  <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                    isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                    value={
                      values.city
                        ? studentCity?.map((l) => ({
                          value: studentCity?.find(
                            (e) => e.name == values.city
                          )?.isoCode,
                          label: studentCity?.find(
                            (e) => e.name == values.city
                          )?.name,
                        }))
                        : null
                    }
                      options={studentCity.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      setValues({
                        ...values,
                        city: option.value,
                        postCode: ''
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('city',false)}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >State</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select a State..."
                      value={
                        values.state
                          ? studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == values.state)?.isoCode,
                            label: studentState?.find((e) => e.name == values.state)?.name,
                          }))
                          : defaultState ?
                            studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                            label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                          : null
                      }
                      options={studentState.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) =>
                      {
                        getCities(
                          option.countryCode,
                          option.isoCode,
                        )
                        setValues({
                          ...values,
                          state: option.value,
                        })
                        checkPostCode(values.postCode, option.value)
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={()=>setFieldTouched('state',false)}
                    />
                    {touched.state && (
                      <p className="text-red-700 error_msg">{errors.state}</p>
                    )}
                  </div></div>
                <div className='mt-4'></div>

                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Postcode</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="postCode"
                  label="postCode"
                  type="text"
                  value={values.postCode}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postCode: e.target.value,
                    })
                    checkPostCode(e.target.value, values.state)
                  }}
                  placeholder="Enter postcode"
                  maxLength={4}
                  onFocus={()=>setFieldTouched('postCode',false)}
                />
                {touched.postCode && (
                  <p className="text-red-700 error_msg">{errors.postCode}</p>
                )}
                {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Account Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="accountName"
                  label="Account Name"
                  type="text"
                  value={values.accountName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Account Name..."
                  onFocus={()=>setFieldTouched('accountName',false)}
                />
                {touched.accountName && (
                  <p className="text-red-700 error_msg">{errors.accountName}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >BSB</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      label="BSB"
                      type="text"
                      value={values.BSB}
                      autoComplete="off"
                      maxLength="6"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank BSB..."
                      onFocus={()=>setFieldTouched('BSB',false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">{errors.BSB}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Account Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNumber"
                      label="Account Number"
                      type="text"
                      value={values.accountNumber}
                      autoComplete="off"
                      maxLength="11"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank Account Number..."
                      onFocus={()=>setFieldTouched('accountNumber',false)}
                    />
                    {touched.accountNumber && (
                      <p className="text-red-700 error_msg">{errors.accountNumber}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Google My Business</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="googleurl"
                  label="Account Number"
                  type="url"
                  value={values.googleurl}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="https://www.google.com/"
                  onFocus={()=>setFieldTouched('googleurl',false)}
                />
                  </div>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Facebook</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="facebook"
                  label="Account Number"
                  type="url"
                  value={values.facebook}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('facebook',false)}
                  placeholder="https://www.facebook.com/"
                />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <div className='flex'>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="abn"
                    type="text"
                    value={values.abn}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={()=>setFieldTouched('abn',false)}
                    placeholder="Enter Australian Business Number..."
                  />
                  <p className="w-8 rounded-r-md border-y border-r border-gray-300  py-1 appearance-none " onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md' /></p>
                </div>{touched.abn && (
                  <p className="text-red-700 error_msg">{errors.abn}</p>
                )}
                {abnError && (
                  <p className="text-red-700 error_msg">{abnError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >GST Register Date</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="gst"
                  label="GST"
                  type="text"
                  value={values.gstValue}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                  onFocus={()=>setFieldTouched('gst',false)}
                />
                {touched.gst && (
                  <p className="text-red-700 error_msg">{errors.gst}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="abnStatus"
                  type="text"
                  value={values.abnStatus}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={abnStatus}

                />
                  </div><div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="entityName"
                  type="text"
                  value={values.entityName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  placeholder={entityName}
                />
                  </div></div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero API Key</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="xeroAPIKey"
                  label="Xero API Key"
                  type="text"
                  value={values.xeroAPIKey}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero API Key..."
                  onFocus={()=>setFieldTouched('xeroAPIKey',false)}
                />
                {touched.xeroAPIKey && (
                  <p className="text-red-700 error_msg">{errors.xeroAPIKey}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero Invoice Branding Theme ID</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="brandingThemeID"
                  type="text"
                  value={values.brandingThemeID}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero Invoice Branding Theme ID..."
                  onFocus={()=>setFieldTouched('brandingThemeID',false)}
                />
                {touched.brandingThemeID && (
                  <p className="text-red-700 error_msg">{errors.brandingThemeID}</p>
                )}
                <div className="mt-4 sm:mt-6"></div>

                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                    disabled={isSubmitting || postCodeError}>
                    {isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Location' : 'Update Location')}
                  </Button>
                </div>
              </div>

            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const cancelInvoice = () => {
    setConfirmationInvoiceButton(false)
    setSubmittingInvoice(false)
  }
  
  const renderInvoice = () => {
    const { type, state, edit_id, data } = invoiceModal

    const quantityEdit = (event, index) => {
      const newQuantity = event.target.value;
      const newData = [...data]
      if(newQuantity > newData[index].service.maxQuantity){
        toast.warning("Reached the maximum session limit.")
      }else {
        newData[index].service.quantity = newQuantity;
        if(newData[index].service.quantity == 0 && newData[index].service.quantity != '') {
          toast.warning("Number of sessions cannot be zero.")
        }else{
          newData[index].totalAmount = newQuantity * newData[index].service.amount;
          let totalAmount = 0
          if (newData[index].totalAmount >= newData[index].discount) {
            newData[index].discountError = false
            totalAmount = newData[index].discount ? newData[index].totalAmount - newData[index].discount : newData[index].totalAmount
          } else {
            newData[index].discountError = true
          }
          newData[index].amountPayable = totalAmount;
          formik.setValues(newData);
        }
      }
    };

    const amountEdit = (value, index) => {
      const newData = [...data];
      newData[index].service.amount = value;
      newData[index].totalAmount = newData[index].service.quantity * value;
      let totalAmount = 0
      if (newData[index].totalAmount >= newData[index].discount) {
        newData[index].discountError = false
        totalAmount = newData[index].discount ? newData[index].totalAmount - newData[index].discount : newData[index].totalAmount
      } else {
        newData[index].discountError = true
      }
      newData[index].amountPayable = totalAmount;
      formik.setValues(newData);
    }

    const calculateDiscount = (value, index) => {
      const newData = [...data];
      const totalAmount = newData[index].totalAmount;
      const discountError = totalAmount < value || totalAmount == value;
      newData[index].discount = value != undefined ? value : 0
      newData[index].discountError = discountError
      newData[index].amountPayable = discountError ? totalAmount : totalAmount - value
      formik.setValues(newData)
    };

    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values) => {
          const error = values.some(e => e?.discount > 0 && e?.descriptionValue == undefined)
          if (error) {
            toast.error("Discount description are required.")
          } else {
            setSubmittingInvoice(true)
            setInvoiceModal((prev) => ({ ...prev, data: values }))
            setConfirmationInvoiceButton(true)
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() => {
              setInvoiceModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Create Invoice'}
            handleClick={''}
            data={''}
            resendInvoice={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="gap-4"> {/*nothing here */}
                {state && values.length > 0 && values?.map((v, i) => (
                  <div key={i} className='border border-gray-300 shadow-full rounded-md p-4 mt-8'>
                    <div className="-mt-6 text-left">
                      <div className="flex justify-between items-center space-x-2">
                        <div className="w-1/4">
                          <div className="flex ">
                            <label className="block text-sm font-medium text-gray-700">
                              Select a Customer
                            </label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <div className="">
                            <Select
                              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              name="customerId"
                              id="customerId"
                              isSearchable
                              loadingMessage="Getting Customer..."
                              placeholder="Select a customer..."
                              value={
                                v.parentValue
                                  ? family.map((l) => ({
                                    value: family.find((e) => e.id == v.parentValue)
                                      ?.id,
                                    label:
                                      family.find((e) => e.id == v.parentValue)
                                        ?.lastName +
                                      ', ' +
                                      family.find((e) => e.id == v.parentValue)
                                        ?.firstName,
                                  }))
                                  : null
                              }
                              filterOption={customFilter}
                              onInputChange={handleInputChange}
                              options={family?.map((l) => ({
                                ...l,
                                label: l.lastName + ', ' + l.firstName,
                                value: l.id,
                              }))}
                              onChange={(option) => {
                                setValues({
                                  ...v,
                                  customerId: option.value,
                                  locationId: ''
                                })
                              }}
                              onBlur={handleBlur}
                              menuPlacement="auto"
                              isDisabled={true}
                              styles={{
                                singleValue: (provided) => ({
                                  ...provided,
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  color: '#414a4c'
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-1/4 ">
                          <div className="flex ">
                            <label className="block text-sm font-medium text-gray-700">
                              Select a Student
                            </label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <div className="">
                            <Select
                              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              isSearchable
                              loadingMessage="Getting Student..."
                              placeholder="Select a student..."
                              value={
                                v.studentValue
                                  ? students?.map((l) => ({
                                    value: students?.find(
                                      (e) => e.id == v.studentValue
                                    )?.id,
                                    label: students?.find((e) => e.id == v.studentValue)?.studentsLastName +
                                      ', ' +
                                      students?.find((e) => e.id == v.studentValue)?.studentsFirstName,
                                  }))
                                  : null
                              }
                              options={students?.map((l) => ({
                                ...l,
                                label: l.studentsFirstName + ', ' + l.studentsLastName,
                                value: l.id,
                              }))}
                              isDisabled={true}
                              onChange={(option) => {
                                setValues({
                                  ...v,
                                  studentLocation: option.studentMaster.locations.map((l) => l.locationId),
                                  // locationId: option.studentMaster.locations[0].locationId
                                })
                                // setLocationAddress(parseInt(option.studentMaster.center))
                              }}
                              onBlur={handleBlur}
                              menuPlacement="auto"
                              maxMenuHeight={110}
                              styles={{
                                singleValue: (provided) => ({
                                  ...provided,
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  color: '#414a4c'
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-1/4 ">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Type of Service
                            </label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="typeOf"
                            id="typeOf"
                            isSearchable
                            loadingMessage="Getting Group Size..."
                            placeholder="Select a type of service..."
                            value={
                              v.typeOfService
                                ? typeOfServiceOptions?.map((l) => ({
                                  value: typeOfServiceOptions?.find(
                                    (e) => e.id == v.typeOfService
                                  )?.id,
                                  label: typeOfServiceOptions?.find(
                                    (e) => e.id == v.typeOfService
                                  )?.name,
                                }))
                                : null
                            }
                            options={typeOfServiceOptions.map((l) => ({
                              ...l,
                              label: l.name,
                              value: l.id,
                            }))}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                typeOfService: option.value,
                              })
                            }}
                            onBlur={handleBlur}
                            menuPlacement="auto"
                            maxMenuHeight={110}
                            isDisabled={true}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#414a4c'
                              })
                            }}
                          />
                          {touched.typeOf && (
                            <p className="text-red-700 error_msg ">
                              {errors.typeOf}
                            </p>
                          )}
                        </div>

                        <div className="w-2/4 mt-4 flex flex-col justify-end">
                          <div className="flex justify-end">
                            <div className="w-2/6">
                              <div className="">
                                <label className="text-sm font-medium text-gray-700">
                                  Select Location
                                </label>
                                <span className="text-red-700 ml-1">*</span>
                              </div>
                              <Select
                                className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-white shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                name="locationAddress"
                                id="locationAddress"
                                isSearchable
                                loadingMessage="Getting Location..."
                                placeholder="Select a location..."
                                value={
                                  v.locationId
                                    ? location?.map((l) => ({
                                      value: location?.find(
                                        (e) => e.id == v.locationId
                                      )?.id,
                                      label: location?.find(
                                        (e) => e.id == v.locationId
                                      )?.name,
                                    }))
                                    : null
                                }
                                isDisabled={true}
                                options={location?.map((l) => ({
                                  ...l,
                                  label: l.name,
                                  value: l.id,
                                }))}
                                onChange={(option) => {
                                  // setLocationAddress(option.value)
                                  setValues({
                                    ...v,
                                    locationId: option.value,
                                  })
                                }}
                                onBlur={handleBlur}
                                menuPlacement="auto"
                                maxMenuHeight={110}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end mt-1">
                            <p className="w-2/6 font-semibold text-sm">
                              {v.locationId &&
                                location.find((l) => l.id == v.locationId)
                                  ?.address}
                              <br />
                              {v.locationId &&
                                location.find((l) => l.id == v.locationId)
                                  ?.city}{' '}
                              {v.locationId &&
                                location.find((l) => l.id == v.locationId)
                                  ?.state}{' '}
                              {v.locationId &&
                                location.find((l) => l.id == v.locationId)
                                  ?.postCode}
                            </p>
                          </div>
                        </div>
                        <div className="w-1/4 h-20 flex justify-center items-center mt-3">
                          <img className="w-48" src={logo} alt="Your Company" />
                        </div>
                      </div>
                      {v.parentValue ? (
                        <>
                          <div className="w-1/4 mt-1 mb-3 font-semibold text-sm">
                            {family.find((l) => l.id == v.parentValue)?.address1}
                            <br />
                            <span>
                              {family.find((l) => l.id == v.parentValue)?.city}{' '}
                              {family.find((l) => l.id == v.parentValue)?.parentState}{' '}
                              {family.find((l) => l.id == v.parentValue)?.postCode}
                            </span>
                            <br />
                            <span>
                              {family
                                .find((l) => l.id == v.parentValue)
                                ?.country?.slice(0)
                                .toUpperCase()}
                            </span>
                          </div>
                        </>
                      ) : null}

                      <div className="flex items-center text-left">
                        <div className="w-1/4">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Invoice Date
                            </label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <DatePicker
                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            name="date"
                            dateFormat="dd-MM-yyyy"
                            id="date"
                            selected={v.invoiceDate}
                            disabled={true}
                            isClearable={false}
                            placeholderText="Date"
                          />
                        </div>

                        <div className="w-1/4 ml-4">
                          <div className="flex">
                            <label className="block text-sm font-medium text-gray-700">
                              Due Date
                            </label>
                            <span className="text-red-700 ml-1">*</span>
                          </div>
                          <DatePicker
                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                            name="invoiceDueDate"
                            dateFormat="dd-MM-yyyy"
                            id="invoiceDueDate"
                            selected={v.invoiceDueDate}
                            onChange={(date) => {
                              const updatedValues = values.map((item, index) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    invoiceDueDate: date
                                  };
                                }
                                return item;
                              });
                              setValues(updatedValues);
                            }}
                            minDate={new Date()}
                            isClearable
                            placeholderText="Due Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4"></div>
                    <div className="flex items-center bg-stone-300 h-12 rounded-t-md">
                      <div className="w-1/4 ml-4 text-black font-semibold text-md">
                        <span>Class</span>
                      </div>
                      <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                        <span>Number of Sessions</span>
                      </div>
                      <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                        <span>Unit Price</span>
                      </div>
                      <div className="w-1/4 mr-4 text-black font-semibold text-md text-center">
                        <span>Amount</span>
                      </div>
                    </div>
                    <div className="max-h-40 overflow-auto bg-slate-50 rounded-b-md shadow-md">
                      <div className="flex items-center h-10">
                        <div className="w-1/4 ml-4 text-black text-sm">
                          <span>{v?.service?.serviceName}</span>
                        </div>
                        <div className="w-1/4 ml-4 text-black text-sm">
                          <input
                            type="number"
                            name="quantity"
                            min={1}
                            value={v?.service?.quantity}
                            onChange={(e) => {
                              quantityEdit(e, i)
                            }}
                            onBlur={handleBlur}
                            max={v.service.maxQuantity}
                            className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                          />
                        </div>
                        <div className="w-1/4 ml-4 text-black text-sm text-center">
                          <CurrencyInput
                            id="amount"
                            name="amount"
                            decimalsLimit={2}
                            value={v?.service?.amount}
                            className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                            prefix="$"
                            onValueChange={(value) => {
                              amountEdit(value, i)
                            }}
                          />
                        </div>
                        <div className="w-1/4 mr-4 text-black text-sm text-center">
                          <span>$ {v?.service?.amount * v?.service?.quantity}</span>
                        </div>
                      </div>
                      <hr className="text-slate-400"></hr>

                    </div>
                    <div className='flex justify-between items-center mt-14 mb-4'>
                      <div className='w-full'>
                        <div className="flex justify-end space-x-10">
                          <div className="bg-gray-200 rounded-lg shadow border mt-4">
                            <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2 space-x-10"   >
                              <p>Cart Subtotal:</p>
                              <p className="pr-2">${v.totalAmount?.toFixed(2)}</p>
                            </div>
                            <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 pt-3">
                              <p>Discount:</p>
                              <CurrencyInput
                                decimalsLimit={2}
                                value={v.discount}
                                // disabled={wholeFormDisable}
                                maxLength={v.totalAmount.toString().length}
                                className="block w-20 bg-gray-200 appearance-none rounded-md py-2 text-right px-2"
                                prefix="$"
                                onValueChange={(value) => {
                                  calculateDiscount(value, i)
                                }}
                              />
                            </div>
                            {v.discountError && (
                              <>
                                <p className="text-sm text-red-600 text-right">
                                  Discount is more then Total Payable Amount
                                </p>
                              </>
                            )}
                            <div className="flex justify-between bg-gray-200 items-center px-2 text-sm font-mono text-gray-700 pt-3">
                              <p className="">Discount Description:</p>
                              <Select
                                className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                isSearchable
                                isDisabled={v.discount == 0}
                                loadingMessage="Getting Discount Description..."
                                placeholder="Description"
                                value={
                                  v.descriptionValue
                                    ? {
                                      value: discountDescription?.find(
                                        (l) => l.id == v.descriptionValue
                                      )?.id,
                                      label: discountDescription?.find(
                                        (l) => l.id == v.descriptionValue
                                      )?.description,
                                    }
                                    : null
                                }
                                options={discountDescription?.map((l) => ({
                                  ...l,
                                  label: l.description,
                                  value: l.id,
                                }))}
                                onChange={(option) => {
                                  const updatedValues = values.map((item, index) => {
                                    if (index === i) {
                                      return {
                                        ...item,
                                        descriptionValue: option.value
                                      };
                                    }
                                    return item;
                                  });
                                  setValues(updatedValues);
                                }}
                                onBlur={handleBlur}
                                menuPlacement="auto"
                                maxMenuHeight={110}
                              />
                            </div>
                            {v.discount > 0 && v.descriptionValue == undefined && (
                              <>
                                <p className="text-sm text-red-600 text-right">
                                  Select a discount description
                                </p>
                              </>
                            )}
                            <hr class="h-px my-2 bg-black border-0 w-full px-2" />
                            <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                              <p>Total Amount Payable:</p>
                              <p
                                className={'ml-20 pr-2'}
                              >${v.amountPayable?.toFixed(2)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className='flex justify-end items-center'>
                  <div className="mt-4 sm:mt-6 bottom-3 right-3">
                    <Button
                      type="submit"
                      disabled={submittingInvoice}
                    >
                          Submit Invoice
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }


  return (
    <Sidebar>
      {renderModal()}
      {renderInvoice()}
      {renderMarkAsPaidModal()}
      {renderFamilyModal()}
      {renderStudent()}
      {renderTeacherModal()}
      {renderLocationModal()}
      {renderMergeModal()}
      <ConfirmationDialog
        setOpen={setConfirmationDialogRemove}
        open={confirmationDialogRemove}
        onDelete={deleteClassData}
        description="Are you sure want to delete this class?"
        confirmationButtonText={'Delete'}
      />
      <ConfirmationDialog
        setOpen={setConfirmationDialogInactive}
        open={confirmationDialogInactive}
        onDelete={onInactive}
        description="Are you sure want to inactive this class?"
        confirmationButtonText={'Inactive'}
      />
      <ConfirmationDialog
        setOpen={cancelInvoice}
        open={confirmationInvoiceButton}
        onDelete={createInvoices}
        description="Are you sure you want to create invoices for all students in this class?"
        confirmationButtonText={'Create Invoice'}
      />
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">
              Class Master
            </h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the classes.
            </p>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <div className="flex justify-end items-start">
              <div className="ml-2"></div>
              <Button
                onClick={() => {
                  setModal((prev) => ({
                    ...prev,
                    state: true,
                    type: 'add',
                    data: {
                      ...prev.data,
                      locationId: location[0]?.id,
                      duration: '60',
                    },
                  }))
                  setLocationSearch(location[0]?.id)
                  setSubmitSchedule([])
                  setPayload([])
                  setStudentValue([])
                  setFrequency(10)
                }}
                className="flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mt-0 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
            <div className="relative mx-2">
              <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                <div></div>
                <div className="flex justify-between gap-4 ">
                  <div
                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                  >
                    {filterData.loading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <CiFilter className="w-6 h-6" />}
                  </div>
                </div>
              </div>

              {/* Filter sidebar */}
              {isFilterSidebarOpen && (
                <div className='h-full'>
                  <div className={`absolute bg-white  border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-50`}>
                    <div className='flex justify-between p-2'>
                      <span className=" text-sm text-gray-700 ">Filters</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          startDate: null,
                          endDate: null,
                          status: null,
                          term: null,
                          location: '',
                          loading: false
                        })
                        handleClearFilters()
                      }}> Reset All </span>
                    </div>
                    <hr className='' />
                    <div className="w-full flex flex-col p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Date Range</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            startDate: null,
                            endDate: null,
                          })
                        }}> Reset </span>
                      </div>
                      <div className="w-full flex gap-2 justify-between">
                        <div className="flex customDatePickerWidth items-center">
                          <DatePicker
                            selected={null}
                            onChange={(dates) => {
                              const [start, end] = dates
                              // setStartDate(start)
                              // setEndDate(end)
                              setFilterData({ ...filterData, startDate: start, endDate: end })
                            }}
                            showIcon
                            isClearable
                            startDate={filterData.startDate}
                            endDate={filterData.endDate}
                            dateFormat="MMM dd, yyyy"
                            selectsRange
                            className="text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            placeholderText="Select Date Range"
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Location</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            location: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="location"
                        id="location"
                        isSearchable
                        loadingMessage="Getting Location..."
                        placeholder="Location"
                        isClearable={true}
                        isMulti
                        options={location?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        value={filterData.location}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            location: option,
                          })
                          // setLocationFilter(option)
                        }}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Status</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            status: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="status"
                        id="status"
                        isSearchable
                        loadingMessage="Getting Status..."
                        placeholder="Status"
                        isClearable={true}
                        options={Active?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            status: option,
                          })
                        }}
                        value={filterData.status}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Term</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            term: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="term"
                        id="term"
                        isSearchable
                        loadingMessage="Getting Term..."
                        placeholder="Term"
                        isClearable={true}
                        options={Terms?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            term: option,
                          })
                        }}
                        value={filterData.term}
                      />
                    </div>
                    <hr className='' />
                    <div className='w-full p-2'>
                      <button className="block w-full text-left hover:bg-gray-100 flex gap-2 border rounded-md py-2 px-2 shadow-sm" onClick={() => setOpenSlider(true)}>
                        <PencilIcon className='w-4 text-indigo-800 hover:text-indigo-700 ' />
                        <span className='text-gray-500 text-sm'>Customize</span>
                      </button>
                    </div>
                    <hr className='' />
                    <div className="flex justify-between gap-2 p-2">
                      <div className="">
                        <Button
                          type="button"
                          className={'bg-red-500 hover:bg-red-700'}
                          onClick={() => {
                            setFilterData({
                              startDate: null,
                              endDate: null,
                              status: null,
                              term:null,
                              location: '',
                              loading: false
                            })
                            handleClearFilters()
                          }}
                        >
                          Reset All
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          type="button"
                          onClick={() => {
                            handleFilters()
                          }}
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {renderTable()}
            {/* <Table
          progPage={page}
          totalCount={count}
          columns={class_column({ onEditOpen, onDeleteOpen,onTeacherOpen,onActiveOpen,onInactiveOpen })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        /> */}
            <nav
              className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                      setPage(0)
                    }}
                    value={limit}
                  >
                    <option value={count}>{count}</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  of <span className="font-medium">{count}</span> results
                </p>
              </div>
              <div className="flex items-center md:justify-end">
                <span
                  onClick={goPrev}
                  className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Previous
                </span>
                <span className="text-sm">
                  Page{' '}
                  <input
                    className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                    type="number"
                    value={page + 1}
                    onChange={(e) => setPage(e.target.value - 1)}
                    max={totalPages}
                    min="1"
                  />
                  <span className="ml-2"></span>/ {Math.ceil(totalPages)}
                </span>
                <span
                  onClick={goNext}
                  className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Next
                </span>
              </div>
            </nav>
          </>}
      </div>
      <ColumnSlideOver
        open={openSlider}
        setOpen={setOpenSlider}
        title={title}
        description={description}
        data={columnFilter}
        handleClick={handleClick}
      />
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default ClassMaster
